import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
// import DataTable from 'react-data-table-component';
import CreateTransaction from '../../components/Transfer/CreateTransaction'
import CreateExpenses from '../../components/EmployeeSummary/CreateExpenses'
import ViewExpenses from '../../components/EmployeeSummary/ViewExpenses'
import { Table, Space } from 'antd'
import Swal from 'sweetalert2'
import SendAPI from '../../utils/SendAPI'

interface SummaryAmountTableProps {
    list: any
    employee: any
    employeeID: any
    accounts: object
    expenseLabels: object
    loading: boolean
    onloadData: () => void
}

const SummaryAmountTable: FC<SummaryAmountTableProps> = ({ list, employeeID, employee, accounts, expenseLabels, loading, onloadData}) => {
	const { sendAPIWithJWT } = SendAPI();
    const [summary, setSummary] = useState(0)
    const [salary, setSalary] = useState(0)
    const [sumTotal, setSumTotal] = useState(0)
    // let data: any = []

    const [createTransactionModal, setCreateTransactionModal] = useState(false)
    const [createExpensesModal, setCreateExpensesModal] = useState(false)
    const [viewExpensesDrawer, setViewExpensesDrawer] = useState(false)
	const toggleTransactionModal = () => setCreateTransactionModal(!createTransactionModal)
	const toggleExpensesModal = () => setCreateExpensesModal(!createExpensesModal)
    const toggleViewExpenses = () => setViewExpensesDrawer(!viewExpensesDrawer)
    const [finances, setFinances] = useState('')
    const handleViewData = (object: any) => setFinances(object)
    
    const [dataEmployee, setDataEmployee] = useState<any>([])
    const [dataOjects, setDataOjects] = useState<any>([])
    
    const handleDeleteData = (id: number) => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: "คุณต้องการลบรายการนี้ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dataResponse: any = await sendAPIWithJWT({
                    path : `/finances/${id}`,
                    method : "delete",
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true})
                        onloadData()
                } else {
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
            }
        })
    };

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    const amountFormat = (amount: number) => {
        if (amount >= 0) {
            return (<span style={{color: '#139B82'}}>+ ฿ {numberWithCommas(amount)}</span>)
        }else if(amount < 0){
            return (<span style={{color: '#DF4B4A'}}>- ฿ {numberWithCommas(Math.abs(amount))}</span>)
        }else{
            return (<span>฿ {numberWithCommas(amount)}</span>)
        }
    }

    const columns = [
        {
            title: 'ประเภทรายการ',
            render: (row: any) => {
                return (
                    <>
                    {row.id !== 0 ? 
                    <div 
                    className="btn-link-view"
                    onClick={() => {
                        handleViewData(row)
                        toggleViewExpenses()
                    }}>{row.expense_label_name ? row.expense_label_name : '-'}</div>
                    : <div>{row.expense_label_name ? row.expense_label_name : '-'}</div>}
                    </>
                )
            },
        },
        {
            title: 'จำนวนเงิน',
            render: (row: any) => amountFormat(row.amount)
        },
        {
            title: 'วันที่',
            render: (row: any) => row.date
        },
        {
            title: 'วันที่ทำรายการ',
            render: (row: any) => row.createdAt
        },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <>{row.id !== 0 && <Space size={0}><button className="btn btn-danger btn-sm" onClick={() => {handleDeleteData(row.id)}}>ลบ</button></Space>}</>
                )
            },
        }
    ]

    useEffect(() => {
        if (Object.keys(list).length > 0) {
            if (list.employee.salary !== 0) {
                setDataOjects([{
                    "id" : 0,
                    "amount": list.employee.salary,
                    "expense_label_name": "เงินเดือน",
                    "date": "-",
                    "createdAt": "-"
                }, ...list.data])
            }
            setDataEmployee(list.employee)
            setSalary(list.employee.salary)
            // คำนวณยอดเงิน
            setSummary(list.data.reduce((curr: number, item: any) => item.amount + curr, 0));
        }

    }, [list, setDataOjects])

    useEffect(() => {
        setSumTotal(salary + summary)
    }, [salary, summary])

    return (
        <>
            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
            <div className="card-head">
                <div className="d-flex mb-3 mb-md-0">
                    <h2 className="my-auto me-3">สรุปเงินเดือน{(Object.keys(dataEmployee).length > 0) ? ` : ${dataEmployee.name} (${dataEmployee.nickname})` : ''} </h2>
                </div>
                <div className="ms-md-auto">
                    <button
                        className="btn btn-primary ms-auto ms-md-0 me-2"
                        onClick={() => toggleTransactionModal()}
                        disabled={dataEmployee.bank_account_name && dataEmployee.bank_account && dataEmployee.bank_name ? false : true}
                    >
                        <i className="far fa-plus me-3"></i>
                        <span>โอนเงิน</span>
                    </button>
                    <button
                        className="btn btn-info ms-auto ms-md-0"
                        onClick={() => toggleExpensesModal()}
                    >
                        <i className="far fa-plus me-3"></i>
                        <span>ค่าใช้จ่าย</span>
                    </button>
                </div>
            </div>
            <Table
                columns={columns as any}
                dataSource={dataOjects}
                rowKey={record => record.id}
                loading={loading}
                pagination={false}
                summary={() => (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>รวม</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={3}>฿ {numberWithCommas(sumTotal)}</Table.Summary.Cell>
                    </Table.Summary.Row>
                )}
                scroll={{ x: true }}
                sticky={{ offsetHeader: 57 }}
            />
            </ListTableCard>
            <CreateTransaction
                employeeList={employee}
                accountList={accounts}
                employeeId={parseInt(employeeID)}
                amount={salary + summary}
	            isOpenCreateData={createTransactionModal}
                closeCreateData={() => toggleTransactionModal()}
                onloadData={() => toggleTransactionModal()}
            />
             <CreateExpenses
                expenseLabelList={expenseLabels}
                employeeID={employeeID}
	            isOpenModal={createExpensesModal}
                closeCreateModal={() => toggleExpensesModal()}
                onloadData={() => onloadData()}
            />
            <ViewExpenses
                listData={finances as any}
	            isOpenViewData={viewExpensesDrawer}
                closeViewData={() => toggleViewExpenses()}
            />
        </>
	);
}

export default SummaryAmountTable

const ListTableCard = styled.div`
    background: #fff;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`