import React, { FC, useEffect, useState } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Select,
    Space,
    notification
} from 'antd'
import SendAPI from '../../utils/SendAPI'

interface CreateDataProps {
    isOpenCreateData: boolean
    closeCreateData: () => void
    onloadData: () => void
}
interface CreateDataInsertProps {
    name: string
    status: string
}

const CreateData: FC<CreateDataProps> = ({ isOpenCreateData, closeCreateData, onloadData }) => {
    const nameTitle = "เพิ่มบริษัท"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState(false)

    const insertData = async (values: CreateDataInsertProps) => {
        const createResponse: any = await sendAPIWithJWT({
            path: "/companies",
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (createResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: createResponse.data?.message
            });
            setBtnLoading(false)
            closeCreateData()
            onloadData()
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: createResponse.message ? createResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: CreateDataInsertProps) => {
                setBtnLoading(true)
                insertData(values)

            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpenCreateData) {
            form.resetFields();
            form.setFieldsValue({ status: 'enable' })
        }
    }, [form, isOpenCreateData]);

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeCreateData}
                visible={isOpenCreateData}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateData} size="large">ยกเลิก</Button>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large"
                            danger
                        >
                            <i className="far fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="createForm"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="ชื่อบริษัท"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อบริษัท' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อบริษัท" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="สถานะ"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                                hasFeedback
                            >
                                <Select placeholder="เลือกสถานะ">
                                    <Option value="enable">เปิด</Option>
                                    <Option value="disable">ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-save me-2"></i> บันทึก</button>
                        <button type="button" className="btn btn-light me-3" onClick={() => closeCreateData()}>ยกเลิก</button>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default CreateData
