import React, { FC, useEffect, useState } from 'react'
import {
    // Modal,
    Drawer,
    Space,
    Button,
    Form,
    Col,
    Row,
    Input,
    Select,
    notification,
    Card,
    Alert
} from 'antd'
// import { Modal } from 'reactstrap'
// import Select from 'react-select'
import SendAPI from '../../utils/SendAPI'

interface CreateTransactionProps {
    employeeList: object
    accountList: object
    employeeId?: number
    amount?: number
    isOpenCreateData: boolean
    closeCreateData: () => void
    onloadData?: () => void

    transaction?: any
}
interface TransactionInsertProps {
    account_id: number
    employee_id: number
    amount: number
    remark?: string
}

const CreateTransaction: FC<CreateTransactionProps> = ({ isOpenCreateData, closeCreateData, onloadData, employeeList, accountList, amount, employeeId, transaction }) => {
    const nameTitle = "โอนเงิน"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState(false)
    const [dataSelectEmployee, setDataSelectEmployee] = useState<any>({})
    const [dataSelectAccount, setDataSelectAccount] = useState<any>({})
    // const patternNumber: any = new RegExp("^([-]?[1-9][0-9]*|0)$")
    const patternNumber: any = new RegExp("^([-]?([0-9]+([.][0-9]*)?|[.][0-9]+))$")
    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }


    const transactionCreate = async (values: TransactionInsertProps) => {
        const dataResponse: any = await sendAPIWithJWT({
            path: `/transfers`,
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (dataResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: dataResponse.data?.message
            });
            setBtnLoading(false)
            closeCreateData()
            if (onloadData) {
                onloadData()
            }
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
            if (onloadData) {
                onloadData()
            }
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: TransactionInsertProps) => {
                setBtnLoading(true)
                values.amount = Number(values.amount)
                transactionCreate(values)
            })
            .catch((errorInfo) => { });
    };

    const onChangeEmployee = (id_employee: any) => {
        setDataSelectEmployee(Object.values(employeeList).find((item: any) => item.id === id_employee))
    };

    const getEmployeeOption = Object.values(employeeList).map((item: any) => {
        return (
            <Option key={item.id} value={item.id} disabled={item.bank_account_name && item.bank_account && item.bank_name ? false : true} title={`${item.name} (${item.nickname})`} >{item.name} ({item.nickname})</Option>
        )

    })

    const getAccountOption = Object.values(accountList).map((item: any) => {
        return <Option key={item.id} value={item.id} title={`${item.bank_name} | ${item.bank_account} (คงเหลือ ${numberWithCommas(item.remaining_balance)} บาท)`}>{item.bank_name} | {item.bank_account} (คงเหลือ {numberWithCommas(item.remaining_balance)} บาท)</Option>
    })

    const onChangeAccount = (id_account: any) => {
        setDataSelectAccount(Object.values(accountList).find((item: any) => item.id === id_account))
    }

    useEffect(() => {
        if (isOpenCreateData) {
            form.resetFields();
            form.setFieldsValue({ amount: amount })
            form.setFieldsValue({ employee_id: employeeId })
            setDataSelectEmployee({})
            setDataSelectAccount({})
            if (employeeId) {
                setDataSelectEmployee(Object.values(employeeList).find((item: any) => item.id === employeeId))
            }

            if (transaction && Object.values(transaction).length > 0) {
                form.setFieldsValue(transaction)
            }
        }
        return () => {
            setDataSelectEmployee({})
            setDataSelectAccount({})
        }
    }, [form, isOpenCreateData, amount, employeeId, employeeList, transaction]);

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeCreateData}
                visible={isOpenCreateData}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateData} size="large">ยกเลิก</Button>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large"
                            danger
                        >
                            <i className="far fa-money-bill-wave-alt me-2"></i>
                            โอนเงิน
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="passwordUserForm"
                    layout="vertical"
                    hideRequiredMark
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="account_id"
                                label="บัญชีต้นทาง"
                                rules={[{ required: true, message: 'กรุณาเลือกบัญชีต้นทาง' }]}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกบัญชีต้นทาง"
                                    showSearch
                                    optionFilterProp="title"
                                    onChange={(e) => onChangeAccount(e)}
                                    filterOption={(input: any, option: any) => {
                                        return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }}
                                >
                                    {getAccountOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="employee_id"
                                label="พนักงาน"
                                rules={[{ required: true, message: 'กรุณาเลือกพนักงาน' }]}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกพนักงาน"
                                    onChange={(e) => onChangeEmployee(e)}
                                    showSearch
                                    optionFilterProp="title"
                                    filterOption={(input: any, option: any) =>
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getEmployeeOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {Object.keys(dataSelectEmployee).length > 0 &&
                        <>
                            <Card title="ข้อมูลบัญชีธนาคาร" size="small" style={{ marginBottom: "10px" }}>
                                <p>ชื่อบัญชี : {dataSelectEmployee.bank_account_name}</p>
                                <p>เลขที่บัญชี : {dataSelectEmployee.bank_account}</p>
                                <p>ธนาคาร : {dataSelectEmployee.bank_name}</p>
                            </Card>
                        </>}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                                // label={`จำนวนเงิน ${Object.values(dataSelectAccount).length > 0 ? `จำนวนเงินที่สามารถโอนได้ในวันนี้ ${numberWithCommas(dataSelectAccount.remaining_transfer_today)}` : ""} `}
                                label="จำนวนเงิน"
                                rules={[
                                    { required: true, message: 'กรุณากรอกจำนวนเงิน' },
                                    { pattern: patternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback
                                extra="กรุณากรอกเป็นจำนวนเต็มบวกหรือจำนวนเต็มลบ เช่น 10 หรือ -10"
                            >
                                <Input placeholder="จำนวนเงิน (บาท)" />
                            </Form.Item>
                            {Object.values(dataSelectAccount).length > 0 && (
                                <Alert message={`จำนวนเงินที่สามารถโอนได้ในวันนี้ ${numberWithCommas(dataSelectAccount.remaining_transfer_today)}`} type="error" />
                            )}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="remark"
                                label="หมายเหตุ"
                                rules={[{ required: false, message: 'กรุณากรอกหมายเหตุ' }]}
                            >
                                <Input.TextArea rows={3} placeholder="หมายเหตุ" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-money-bill-wave-alt me-2"></i> โอนเงิน</button>
                        <button type="button" className="btn btn-light me-3" onClick={() => closeCreateData()}>ยกเลิก</button>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default CreateTransaction