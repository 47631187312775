import React, { FC, useState, useEffect } from 'react'
import { 
    Drawer, 
    Form, 
    Button, 
    Col, 
    Row, 
    Input, 
    Select,
    Space,
    notification } from 'antd';
import SendAPI from '../../utils/SendAPI'

interface EditDataProps {
    listData: any
	closeEditData: () => void
	isOpenEditData: boolean
	onloadData: () => void
}

interface EditDataUpdateProps {
    id: number
	name: string
	code: string
	status: string
}

const EditData: FC<EditDataProps> = ({ closeEditData, isOpenEditData, onloadData, listData }) => {
    const nameTitle = "แก้ไขธนาคาร"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState(false)

    const UpdateAccount = async (values: EditDataUpdateProps) => {
        const updateResponse: any = await sendAPIWithJWT({
			path : `/banks/${listData.id}`,
			method : "put",
			headers : {'Content-Type': 'application/json'},
			data : values
		});
        if (updateResponse.status === 200) {
			notification.success({
				message: 'Success',
				description: updateResponse.data?.message
			});
            setBtnLoading(false)
            closeEditData()
            form.resetFields()
            onloadData()
        }else{
            // 400
			notification.error({
				message: 'Error',
				description: updateResponse.message ? updateResponse.message : "เกิดข้อผิดพลาด"
			});
            setBtnLoading(false)
        }
        
    }

    const handleFormSubmit = () => {
		form.validateFields()
			.then((values: EditDataUpdateProps) => {
                setBtnLoading(true)
                UpdateAccount(values)
			})
			.catch((errorInfo) => {});
	};

    useEffect(() => {
        if (form && isOpenEditData && listData) {
            form.resetFields();
            form.setFieldsValue(listData)
        }
    }, [form, isOpenEditData, listData])
    
	return (
        <>
        <Drawer
            title={nameTitle}
            onClose={closeEditData}
            visible={isOpenEditData}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
            <Space>
                <Button onClick={closeEditData} size="large">ยกเลิก</Button>
                <Button 
                onClick={handleFormSubmit} 
                type="primary" 
                loading={btnLoading} 
                size="large"
                danger
                >
                <i className="far fa-save me-2"></i>
                บันทึก
                </Button>
            </Space>
            }
        >
            <Form 
            form={form}
            name="updateForm"
            layout="vertical" 
            initialValues={listData}
            hideRequiredMark>
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="name"
                    label="ชื่อธนาคาร"
                    rules={[{ required: true, message: 'กรุณากรอกชื่อธนาคาร' }]}
                    hasFeedback
                >
                    <Input placeholder="ชื่อธนาคาร" />
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="code"
                    label="Code"
                    rules={[{ required: true, message: 'กรุณากรอก Code' }]}
                    hasFeedback
                >
                    <Input placeholder="Code" />
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="status"
                    label="สถานะ"
                    rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                    hasFeedback
                >
                    <Select placeholder="เลือกสถานะ">
                    <Option value="enable">เปิด</Option>
                    <Option value="disable">ปิด</Option>
                    </Select>
                </Form.Item>
                </Col>
            </Row>
            <div className="text-end">
                <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-save me-2"></i> บันทึก</button>
                <button type="button" className="btn btn-light me-3" onClick={() => closeEditData()}>ยกเลิก</button>
            </div>
            </Form>
        </Drawer>
        </>
	)
}
export default EditData
