import React, { FC, useEffect, useState } from 'react'
import { 
    Drawer, 
    Button, 
    Space,
    Divider, 
    Descriptions,} from 'antd';

import {GenQRCode} from '../../utils/QRCodeTransfer'
interface ViewDataProps {
    listData: any
	closeViewData: () => void
	isOpenViewData: boolean
}

const ViewTransfer: FC<ViewDataProps> = ({ closeViewData, isOpenViewData, listData }) => {
    const nameTitle = "ข้อมูลการโอนเงิน"
    const [statusShow, setStatusShow] = useState('')
    const [amountShow, setAmountShow] = useState(0)

    const [viewData, setViewData] = useState(false)
    const toggleViewData = () => setViewData(!viewData)

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }
    useEffect(() => {
        const statusList: any = [{status: 'pending', name: 'รออนุมัติ'},{status: 'approved', name: 'อนุมัติ'},{status: 'reject', name: 'ไม่อนุมัติ'}]
        if (listData) {
            setStatusShow(statusList.find((item: any) => {return item.status === listData.status}).name)
            setAmountShow(numberWithCommas(listData.amount))
        }
    }, [listData])


	return (
        <>
        <Drawer
            title={nameTitle}
            onClose={closeViewData}
            visible={isOpenViewData}
            bodyStyle={{ paddingBottom: 80 }}
            width={global.window.innerWidth <= 768 ? global.window.innerWidth : global.window.innerWidth  * .4}
            extra={
            <Space>
                <Button onClick={() => toggleViewData()}  type="primary" size="large">ดูข้อมูลเชิงลึก</Button>
                <Button onClick={closeViewData} size="large">ปิด</Button>
            </Space>
            } >
            <Descriptions title="ข้อมูลบัญชีต้นทาง" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="ชื่อบัญชี">{listData.account_bank_name}</Descriptions.Item>
                <Descriptions.Item label="เลขบัญชี">{listData.account_bank_account}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="ข้อมูลบัญชีปลายทาง" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="ชื่อบัญชี">{listData.employee_bank_account_name} ({listData.employee_nickname})</Descriptions.Item>
                <Descriptions.Item label="เลขบัญชี">{listData.employee_bank_account}</Descriptions.Item>
                <Descriptions.Item label="ธนาคาร">{listData.employee_bank_name}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="ข้อมูลการโอน" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="จำนวนเงิน">{`฿ ${amountShow}`}</Descriptions.Item>
                <Descriptions.Item label="หมายเหตุ">{listData.remark}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="ข้อมูลระบบ" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="#">{listData.id}</Descriptions.Item>
                <Descriptions.Item label="สถานะ">{statusShow}</Descriptions.Item>
                <Descriptions.Item label="วันที่สร้าง">{listData.createdAt}</Descriptions.Item>
                <Descriptions.Item label="สร้างโดย">{listData.created_user_name}</Descriptions.Item>
                <Descriptions.Item label="วันที่แก้ไข">{listData.updatedAt}</Descriptions.Item>
                <Descriptions.Item label="แก้ไขโดย">{listData.updated_user_name}</Descriptions.Item>
            </Descriptions>
            <Drawer
                title="ข้อมูลเชิงลึก"
                width={global.window.innerWidth <= 768 ? global.window.innerWidth : global.window.innerWidth  * .4}
                onClose={() => toggleViewData()}
                visible={viewData}
                extra={
                    <Space>
                        <Button onClick={() => toggleViewData()} size="large">ปิด</Button>
                    </Space>
                } 
            >
                <Descriptions title="Logs Worker" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                    <Descriptions.Item label="ID">{listData?.transaction_log?.id ? listData?.transaction_log?.id : ''}</Descriptions.Item>
                    <Descriptions.Item label="Error">{listData?.transaction_log?.error ? listData?.transaction_log?.error : '-'}</Descriptions.Item>
                    <Descriptions.Item label="QR Code">{listData?.transaction_log?.qr_string ? listData?.transaction_log?.qr_string : '-'}</Descriptions.Item>
                    <Descriptions.Item label="Transaction DateTime ">{listData?.transaction_log?.transaction_date_time ? listData?.transaction_log?.transaction_date_time : '-'}</Descriptions.Item>
                    <Descriptions.Item label="QR Code Image">
                    {listData?.transaction_log?.qr_string ? <img src={GenQRCode(listData?.transaction_log?.qr_string)} alt="IdCard" style={{ width: '200px' }} />  : '-'} 
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </Drawer>
        </>
	)
}
export default ViewTransfer
