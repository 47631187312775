import React, { Fragment, useEffect, useState } from 'react'
import CompanyTable from '../../components/Companys/Table'
// import AccounstMockup from '../../mockup-json/AccountsMockup.json'
import SendAPI from '../../utils/SendAPI'
const Companys = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataCompanys, setDataCompanys] = useState<any>([])
    const getCompanys = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/companies",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataCompanys(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }
    useEffect(() => {
        getCompanys()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <CompanyTable list={dataCompanys} loading={loading} onloadData={getCompanys} />
            </div>
        </Fragment>
    )
}

export default Companys