import React, { FC, useEffect, useState } from 'react'
import { 
    Drawer, 
    Form, 
    Button, 
    Col, 
    Row, 
    Input, 
    Select,
    Space,
    notification,
    Divider} from 'antd'
import SendAPI from '../../utils/SendAPI'

interface CreateAccountProps {
	isOpenCreateDrawer: boolean
	closeCreateDrawer: () => void
	onloadData: () => void
}
interface CreateAccountInsertProps {
	bank_name: string
	bank_account: string
	// encrypt: string
	status: string
    device_id?: string
    license?: string
    pin?: string
}

const CreateAccount: FC<CreateAccountProps> = ({ isOpenCreateDrawer, closeCreateDrawer, onloadData }) => {
    const nameTitle = "เพิ่มบัญชี"
	const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState(false)
    // const [defaultEcrypt] = useState({"license": "", "deviceId": "", "pin": ""})
    const patternNumber: any = new RegExp("^[0-9]{6,6}$")

    const insertAccount = async (values: CreateAccountInsertProps) => {
        const createResponse: any = await sendAPIWithJWT({
			path : "/accounts",
			method : "post",
			headers : {'Content-Type': 'application/json'},
			data : values
		});
        if (createResponse.status === 200) {
			notification.success({
				message: 'Success',
				description: createResponse.data?.message
			});
            setBtnLoading(false)
            closeCreateDrawer()
            onloadData()
        }else{
            // 400
			notification.error({
				message: 'Error',
				description: createResponse.message ? createResponse.message : "เกิดข้อผิดพลาด"
			});
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
		form.validateFields()
			.then((values: CreateAccountInsertProps) => {
                setBtnLoading(true)
                insertAccount(values)
                
			})
			.catch((errorInfo) => {});
	};

    useEffect(() => {
        if (form && isOpenCreateDrawer) {
            form.resetFields();
            // form.setFieldsValue({encrypt : JSON.stringify(defaultEcrypt,null, '\t'), status : 'enable'})
        }
      }, [form, isOpenCreateDrawer]); //defaultEcrypt

	return (
        <>
        <Drawer
            title={nameTitle}
            onClose={closeCreateDrawer}
            visible={isOpenCreateDrawer}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
            <Space>
                <Button onClick={closeCreateDrawer} size="large">ยกเลิก</Button>
                <Button 
                onClick={handleFormSubmit} 
                type="primary" 
                loading={btnLoading} 
                size="large"
                danger
                >
                <i className="far fa-save me-2"></i>
                บันทึก
                </Button>
            </Space>
            }
        >
            <Form 
            form={form}
            name="createAccount"
            layout="vertical" 
            hideRequiredMark>
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="bank_name"
                    label="ชื่อบัญชี"
                    rules={[{ required: true, message: 'กรุณากรอกชื่อบัญชี' }]}
                    hasFeedback
                >
                    <Input placeholder="ชื่อบัญชี" />
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="bank_account"
                    label="เลขที่บัญชี"
                    rules={[{ required: true, message: 'กรุณากรอกเลขที่บัญชี' }]}
                    hasFeedback
                >
                    <Input placeholder="เลขที่บัญชี" />
                </Form.Item>
                </Col>
            </Row>
            <Divider orientation="left" >
                SCB API
            </Divider>
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="device_id"
                    label="Device ID"
                    rules={[{ required: false, message: 'กรุณากรอก Device ID' }]}
                    hasFeedback
                >
                    <Input placeholder="Device ID" />
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="license"
                    label="License"
                    rules={[{ required: false, message: 'กรุณากรอกLicense' }]}
                    hasFeedback
                >
                    <Input placeholder="License" />
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="pin"
                    label="PIN"
                    rules={[
                        { required: false, message: 'กรุณากรอก PIN' },
                        { pattern: patternNumber, message: 'กรุณากรอก PIN เป็นตัวเลขจำนวน 6 ตัวเท่านั้น'}
                    ]}
                    hasFeedback
                    extra="กรอก PIN เป็นตัวเลขจำนวน 6 ตัว"
                >
                    <Input placeholder="PIN" />
                </Form.Item>
                </Col>
            </Row>
            {/* <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="encrypt"
                    label="Encrypt"
                    rules={[{ required: true, message: 'กรุณากรอก Encrypt'}]}
                >
                    <Input.TextArea rows={6} placeholder="Encrypt" />
                </Form.Item>
                </Col>
            </Row> */}
            <Divider />
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="status"
                    label="สถานะ"
                    rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                    hasFeedback
                >
                    <Select placeholder="เลือกสถานะ">
                    <Option value="enable">เปิด</Option>
                    <Option value="disable">ปิด</Option>
                    </Select>
                </Form.Item>
                </Col>
            </Row>
            <div className="text-end">
                <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-save me-2"></i> บันทึก</button>
                <button type="button" className="btn btn-light me-3" onClick={() => closeCreateDrawer()}>ยกเลิก</button>
            </div>
            </Form>
        </Drawer>
        </>
	)
}
export default CreateAccount
