import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UncontrolledCollapse } from 'reactstrap'
import logo from './../../assets/img/logo.png'
import './../../assets/scss/fontawesome.scss'
import './../../assets/scss/sidebar.scss'
// import useAuth from '../../utils/Auth'
// import ls from 'localstorage-slim'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

const Sidebar = () => {
  const { allows } = useSelector((state: ApplicationState) => state.auth)
  const currentLocation = useLocation().pathname;
  return (
    <>
      <div className="sidebar">
        <div className="sidebar-body">
          <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
            <img src={logo} className="sidebar-logo" alt="logo sos hr" />
          </a>
          <div className="sidebar-menu">
            <ul className="nav nav-pills flex-column">
              {Object.values(allows).find((item: any) => item === "DASHBOARD_PAGE") &&
                <li className="nav-item">
                  <Link to="/dashboard"
                    className={"nav-link link-dark" + (currentLocation === '/dashboard' ? ' active' : '')}
                  >
                    <i className="fas fa-tachometer-fast"></i>
                    <span>ภาพรวม</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) => item === "TRANSFER_PAGE") &&
                <li className="nav-item">
                  <Link to="/transfer"
                    className={"nav-link link-dark" + (currentLocation === '/transfer' ? ' active' : '')}
                  >
                    <i className="far fa-exchange"></i>
                    <span>โอนเงิน</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) => item === "EMPLOYEE_PAGE") &&
                <li>
                  <Link to="/employees"
                    className={"nav-link link-dark" + (currentLocation === '/employees' || `/${currentLocation.split("/")[1]}` === '/employees' ? ' active' : '')}
                  >
                    <i className="fas fa-users"></i>
                    <span>พนักงาน</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) => item === "ACCOUNT_PAGE") &&
                <li>
                  <Link to="/accounts"
                    className={"nav-link link-dark" + (currentLocation === '/accounts' ? ' active' : '')}
                  >
                    <i className="far fa-university"></i>
                    <span>บัญชีธนาคาร</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) => item === "USER_PAGE") &&
                <li>
                  <Link to="/users"
                    className={"nav-link link-dark" + (currentLocation === '/users' ? ' active' : '')}
                  >
                    <i className="fas fa-user"></i>
                    <span>ผู้ใช้งาน</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) =>
                item === "BANK_PAGE" ||
                item === "DEPARTMENT_PAGE" ||
                item === "POSITION_PAGE" ||
                item === "EXPENSE_LABEL_PAGE") &&
                <li className="nav-item">
                  <Link to="#"
                    id="settingNavLink"
                    className={"nav-link link-dark" + (
                      currentLocation === '/setting-banks' || currentLocation === '/setting-departments' || currentLocation === '/setting-position' || currentLocation === '/setting-expense-labels' ? ' active' : ''
                    )}
                  >
                    <i className="fad fa-cog"></i>
                    <span>ตั้งค่า</span>
                    <i className="far fa-chevron-down"></i>
                  </Link>
                  <UncontrolledCollapse className="nav-sub-item" toggler="#settingNavLink"
                    defaultOpen={(
                      currentLocation === '/setting-banks' || currentLocation === '/setting-departments' ||
                      currentLocation === '/setting-position' || currentLocation === '/setting-expense-labels') ? true : false}>
                    {Object.values(allows).find((item: any) => item === "BANK_PAGE") &&
                      <Link
                        to="/setting-banks"
                        className={"nav-sub-link" + (currentLocation === '/setting-banks' ? ' active' : '')}
                      >
                        <span>ธนาคาร</span>
                      </Link>
                    }
                    {Object.values(allows).find((item: any) => item === "COMPANY_PAGE") &&
                      <Link
                        to="/setting-companys"
                        className={"nav-sub-link" + (currentLocation === '/setting-companys' ? ' active' : '')}
                      >
                        <span>บริษัท</span>
                      </Link>
                    }
                    {Object.values(allows).find((item: any) => item === "DEPARTMENT_PAGE") &&
                      <Link
                        to="/setting-departments"
                        className={"nav-sub-link" + (currentLocation === '/setting-departments' ? ' active' : '')}
                      >
                        <span>แผนก</span>
                      </Link>
                    }
                    {Object.values(allows).find((item: any) => item === "POSITION_PAGE") &&
                      <Link
                        to="/setting-position"
                        className={"nav-sub-link" + (currentLocation === '/setting-position' ? ' active' : '')}
                      >
                        <span>ตำแหน่ง</span>
                      </Link>
                    }
                    {Object.values(allows).find((item: any) => item === "EXPENSE_LABEL_PAGE") &&
                      <Link
                        to="/setting-expense-labels"
                        className={"nav-sub-link" + (currentLocation === '/setting-expense-labels' ? ' active' : '')}
                      >
                        <span>ค่าใช้จ่าย</span>
                      </Link>
                    }
                  </UncontrolledCollapse>
                </li>
              }
            </ul>
          </div>
          <div className="nav-pills sidebar-footer">
            <a href="/logout" className="nav-link link-dark">
              <i className="fas fa-sign-out"></i>
              <span>ออกจากระบบ</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
