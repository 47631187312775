import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
// import DataTable from 'react-data-table-component'
import CreateUser from '../../components/Users/CreateUser'
import EditUser from '../../components/Users/EditUser'
import PasswordUser from '../../components/Users/PasswordUser'
import ViewUser from '../../components/Users/ViewUser'
import { Input, Switch, notification, Table, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Swal from 'sweetalert2'
import SendAPI from '../../utils/SendAPI'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'

interface UserTableProps {
    list: any
    loading: boolean
	onloadData: () => void
}

const UserTable: FC<UserTableProps> = ({ list, loading, onloadData }) => {
	const { sendAPIWithJWT } = SendAPI();
    const [filterText, setFilterText] = useState('')
	// const [resetPaginationToggle] = useState(false)

    const [dataOjects, setDataOjects] = useState<any>([])

    const [createUserDrawer, setCreateUserDrawer] = useState(false)
    const [editUserDrawer, setEditUserDrawer] = useState(false)
    const [passwordDrawer, setPasswordDrawer] = useState(false)
    const [viewUserDrawer, setViewUserDrawer] = useState(false)
    const toggleEditUser = () => setEditUserDrawer(!editUserDrawer)
    const toggleCreateUser = () => setCreateUserDrawer(!createUserDrawer)
    const togglePasswordUser = () => setPasswordDrawer(!passwordDrawer)
    const toggleViewUser = () => setViewUserDrawer(!viewUserDrawer)

    const [switchLoading, setSwitchLoading] = useState(false)
    const [user, setUser] = useState('')
    const handleEditUser = (object: any) => setUser(object)
    const handleDeleteUser = (id: number) => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: "คุณต้องการลบรายการนี้ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dataResponse: any = await sendAPIWithJWT({
                    path : `/users/${id}`,
                    method : "delete",
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true})
                    onloadData()
                }else{
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
            }
        })
    };

    const handleStatusUser = async (status: boolean, id: number) => {
        if (id) {
            let statusUpdate = status ? "enable" : "disable"
            const dataResponse: any = await sendAPIWithJWT({
                path : `/users/${id}`,
                method : "patch",
                headers : {'Content-Type': 'application/json'},
                data : {
                    "status": statusUpdate
                }
            });
            if (dataResponse.status === 200) {
                notification.success({
                    message: 'Success',
                    description: dataResponse.data?.message
                });
                list[list.findIndex((el: any) => el.id === id)].status = dataResponse.data?.data.status
            }else{
                notification.error({
                    message: 'Error',
                    description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
                });
            }
            setSwitchLoading(false)
        }
    };
    const columns = [
        {
            title: '#',
            render: (row: any, key : number) => row.serial,
            width: '72px',
            defaultSortOrder: 'ascend',
            sorter: (a:any, b:any) => a.serial - b.serial,
        },
        {
            title: 'ชื่อ - นามสกุล',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div
                            className="btn-link-view"
                            onClick={() => {
                                handleEditUser(row)
                                toggleViewUser()
                        }}>
                            {row.first_name}
                            </div>
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'ชื่อผู้ใช้งาน',
            render: (row: any) => row.username,
        },
        {
            title: 'สิทธิ์',
            width: '10%',
            render: (row: any) => row.role.charAt(0).toUpperCase() + row.role.slice(1),
        },
        {
            title: 'สถานะ',
            width: '10%',
            render: (row: any) => {
                let switchStatus = (row.status === "enable") ? true : false
                return (
                <>
                    <Switch
                        checkedChildren="เปิด"
                        unCheckedChildren="ปิด"
                        checked={switchStatus}
                        loading={switchLoading}
                        onChange={(e) =>{
                            setSwitchLoading(true)
                            handleStatusUser(e, row.id)
                        }}
                    />
                </>
                )
            },
        },
        {
            title: 'วันที่สร้าง',
            render: (row: any) => row.createdAt,
        },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <Space size={0}>
                        <button
                            className="btn btn-success btn-sm text-white me-2"
                            onClick={() => {
                                handleEditUser(row)
                                togglePasswordUser()
                            }}
                        >
                            รหัสผ่าน
                        </button>
                        <button
                            className="btn btn-warning btn-sm text-white me-2"
                            onClick={() => {
                                handleEditUser(row)
                                toggleEditUser()
                            }}
                        >
                            แก้ไข
                        </button>
                        <button className="btn btn-danger btn-sm" onClick={() => {handleDeleteUser(row.id)}}>ลบ</button>
                    </Space>
                )
            },
            
        }
    ]

    // const customStyles = {
    //     head: {
    //         style: {
    //             color: 'rgba(0, 0, 0, .5)',
    //             fontSize: '14px',
    //         },
    //     },
    //     headRow: {
    //         style: {
    //             borderBottomWidth: '0',
    //         }
    //     },
    //     rows: {
    //         style: {
    //             fontSize: '16px',
    //             minHeight: '76px',
    //             '&:not(:last-of-type)': {
    //                 borderBottomWidth: '0',
    //             },
    //             '&:nth-of-type(2n)': {
    //                 background: '#F8F9FB',
    //                 borderRadius: '5px'
    //             },
    //         }
    //     },
    //     pagination: {
    //         style: {
    //             fontSize: '14px',
    //             borderTopWidth: '0',
    //         }
    //     }
    // }

    // const paginationComponentOptions = {
    //     rowsPerPageText: 'รายการต่อหน้า',
    //     rangeSeparatorText: 'ถึง',
    // }

    useEffect(() => {
        if (Object.keys(list).length > 0) {
            const filteredItems = (list as any).filter(
                (item: any) => (
                    (item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase())) || 
                    (item.username && item.username.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );
            filteredItems.forEach((item: any, index: number) => { item.serial = index + 1; });
            setDataOjects(filteredItems)
        }
        
    }, [list, filterText, setDataOjects])
    

    return (
        <>
            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <div className="card-head">
                    <div className="row mb-md-3 align-items-md-center">
                        <div className="col-6 col-md-auto d-flex align-items-center">
                            <h2>ผู้ใช้งาน</h2>
                        </div>
                        <div className="col-6 col-md-auto ms-md-auto">
                            <button
                                className="btn btn-primary mb-3 mb-md-0"
                                onClick={() => toggleCreateUser()}
                            >
                                <i className="far fa-plus me-3"></i>
                                <span>เพิ่มผู้ใช้งาน</span>
                            </button>
                        </div>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <Input
                                size="large"
                                addonBefore={<SearchOutlined style={{ marginBottom: '6px' }}/>}
                                allowClear
                                placeholder="ค้นหา"
                                value={filterText ?? ''}
                                onChange={(e: any) => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns as any}
                    dataSource={dataOjects}
                    rowKey={record => record.id}
                    loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                    onChange={ScrollToTopPagination}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50', '80', '100'],
                        position: ['topRight', 'bottomRight'],
                        locale:{items_per_page: " รายการ"}
                    }}
                />
            </ListTableCard>

            <CreateUser
	            isOpenCreateDrawer={createUserDrawer}
                closeCreateDrawer={() => toggleCreateUser()}
                onloadData={() => onloadData()}
            />

            <EditUser
                user={user as any}
	            isOpenEditDrawer={editUserDrawer}
                closeEditDrawer={() => toggleEditUser()}
                onloadData={() => onloadData()}
            />

            <PasswordUser
                user={user as any}
	            isOpenPasswordDrawer={passwordDrawer}
                closePasswordDrawer={() => togglePasswordUser()}
                onloadData={() => onloadData()}
            />

            <ViewUser
                user={user as any}
	            isOpenViewDrawer={viewUserDrawer}
                closeViewDrawer={() => toggleViewUser()}
                swapDrawerEdit={() => {
                    toggleViewUser()
                    toggleEditUser()
                }}
            />

            

        </>
	);
}

export default UserTable

const ListTableCard = styled.div`
    background: #fff;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`
const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`