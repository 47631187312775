import React, { FC, useEffect, useState } from 'react'
import { 
    Drawer, 
    Button, 
    Space,
    Divider, 
    Descriptions,} from 'antd';

interface ViewDataProps {
    listData: any
	closeViewData: () => void
	isOpenViewData: boolean
	swapDataEdit: () => void
}

const ViewData: FC<ViewDataProps> = ({ closeViewData, isOpenViewData, swapDataEdit, listData }) => {
    const nameTitle = "ข้อมูลธนาคาร"
    const [statusShow, setStatusShow] = useState('')
    useEffect(() => {
        const statusList: any = [{status: 'enable', name: 'เปิด'},{status: 'disable', name: 'ปิด'}]
        if (listData) {
            setStatusShow(statusList.find((item: any) => {return item.status === listData.status}).name)
        }
    }, [listData])
	return (
        <>
        <Drawer
            title={nameTitle}
            onClose={closeViewData}
            visible={isOpenViewData}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
            <Space>
                <Button onClick={closeViewData} size="large">ปิด</Button>
                <Button 
                    onClick={swapDataEdit} 
                    type="primary" 
                    size="large"
                    danger
                    >
                    <i className="far fa-edit me-2"></i>
                    แก้ไข
                </Button>
            </Space>
            }
        >
            <Descriptions title={nameTitle} column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="ชื่อธนาคาร">{listData.name}</Descriptions.Item>
                <Descriptions.Item label="Code">{listData.code}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="ข้อมูลระบบ" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="สถานะ">{statusShow}</Descriptions.Item>
                <Descriptions.Item label="วันที่สร้าง">{listData.createdAt}</Descriptions.Item>
                <Descriptions.Item label="สร้างโดย">{listData.created_user_name}</Descriptions.Item>
                <Descriptions.Item label="วันที่แก้ไข">{listData.updatedAt}</Descriptions.Item>
                <Descriptions.Item label="แก้ไขโดย">{listData.updated_user_name}</Descriptions.Item>
            </Descriptions>
            
        </Drawer>
        </>
	)
}
export default ViewData
