import React, { Fragment, useEffect, useState } from 'react'
import DepartmentTable from '../../components/Departments/Table'
// import AccounstMockup from '../../mockup-json/AccountsMockup.json'
import SendAPI from '../../utils/SendAPI'
const Departments = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataDepartments, setDataDepartments] = useState<any>([])
    const getDepartments = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/departments",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataDepartments(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }
    useEffect(() => {
        getDepartments()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <DepartmentTable list={dataDepartments} loading={loading} onloadData={getDepartments} />
            </div>
        </Fragment>
    )
}

export default Departments