import React, { Fragment, useEffect, useState } from 'react'
import TransferTable from '../../components/Transfer/TransferTable'
// import ListMockup from '../../mockup-json/ListMockup.json'
// import EmployeesMockup from '../../mockup-json/EmployeesMockup.json'
import SendAPI from '../../utils/SendAPI'
import moment from 'moment'

const Transfer = () => {

    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)

    // const [dateStartSearch, setDateStartSearch] = useState<any>(moment().startOf('isoWeek'))
    // const [dateEndSearch, setDateEndSearch] = useState<any>(moment().endOf('isoWeek'))
    const [dateStartSearch, setDateStartSearch] = useState<any>()
    const [dateEndSearch, setDateEndSearch] = useState<any>()

    const [dataTransfers, setDataTransfers] = useState<any>([])
    const [dataEmployees, setDataEmployees] = useState<any>([])
    const [dataAccounts, setDataAccounts] = useState<any>([])
    const [dataDepartments, setDataDepartments] = useState<any>([])
    const [dataCompanys, setDataCompanys] = useState<any>([])
    const getTransfers = async (dateStart: any, dateEnd: any) => {
        let date = ""
        if (dateStart && dateEnd) {
            const sDate = moment(dateStart).format('YYYY-MM-DD');
            const eDate = moment(dateEnd).format('YYYY-MM-DD');
            date = `?sdate=${sDate}&edate=${eDate}`
        }
        const dataResponse: any = await sendAPIWithJWT({
            path: `/transfers${date}`,
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataTransfers(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const getEmployees = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/employees",
            method: "get",
        });

        if (dataResponse.status === 200) {
            setDataEmployees(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const getAccounts = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/accounts?status=enable",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataAccounts(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const getDepartments = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/departments",
            method: "get",
        });

        if (dataResponse.status === 200) {
            setDataDepartments(dataResponse.data?.data)
        }
        return dataResponse
    }

    const getCompanys = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/companies",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataCompanys(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }

    const changeSearchDate = (date: any) => {
        if (date !== null) {
            setDateStartSearch(date[0])
            setDateEndSearch(date[1])
        } else {
            setDateStartSearch(null)
            setDateEndSearch(null)
        }
    }

    const onSearch = () => {
        setLoading(true)
        getTransfers(dateStartSearch, dateEndSearch)
    }

    useEffect(() => {
        onSearch()
        getEmployees()
        getAccounts()
        getDepartments()
        getCompanys()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <TransferTable
                    list={dataTransfers}
                    employees={dataEmployees}
                    accounts={dataAccounts}
                    departments={dataDepartments}
                    companys={dataCompanys}
                    loading={loading}
                    onloadData={onSearch}
                    onDateSearch={(e) => changeSearchDate(e)}
                />
            </div>
        </Fragment>
    )
}

export default Transfer