import React, { FC, useEffect, useState } from 'react'
import {
    Drawer,
    Button,
    Space,
    Divider,
    Descriptions,
    Modal,
} from 'antd';
import SendAPI from '../../utils/SendAPI'
import { EyeOutlined } from '@ant-design/icons';


interface ViewEmployeeProps {
    employee: any
    closeViewDrawer: () => void
    isOpenViewDrawer: boolean
    swapDrawerEdit: () => void
}

const ViewEmployee: FC<ViewEmployeeProps> = ({ closeViewDrawer, isOpenViewDrawer, swapDrawerEdit, employee }) => {
    const { sendAPIWithJWT } = SendAPI();
    const nameTitle = "ข้อมูลพนักงาน"
    const [statusShow, setStatusShow] = useState('')
    const [salaryShow, setSalaryShow] = useState('')
    const [imageDownload, setImageDownload] = useState('')
    const [previewVisible, setPreviewVisible] = useState(false)
    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return String(num);
    }

    const downloadImage = async (filename: string) => {
        const downloadImg: any = await sendAPIWithJWT({
            path: `/employees/idcard/download?filename=${filename}`,
            method: "get"
        });
        if (downloadImg.status === 200) {
            setImageDownload(downloadImg.data.url);
        } else {
            setImageDownload('');
        }
    }


    useEffect(() => {
        const statusList: any = [{ status: 'enable', name: 'เปิด' }, { status: 'disable', name: 'ปิด' }]
        if (employee) {
            setStatusShow(statusList.find((item: any) => { return item.status === employee.status }).name)
            setSalaryShow(employee.salary !== 0 ? numberWithCommas(employee.salary) : '')
            setImageDownload('');
            employee.identification_card_image && downloadImage(employee.identification_card_image)
        }
    }, [employee])// eslint-disable-line

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeViewDrawer}
                visible={isOpenViewDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeViewDrawer} size="large">ปิด</Button>
                        <Button
                            onClick={swapDrawerEdit}
                            type="primary"
                            size="large"
                            danger
                        >
                            <i className="far fa-edit me-2"></i>
                            แก้ไข
                        </Button>
                    </Space>
                }
            >
                <Descriptions title={nameTitle} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                    <Descriptions.Item label="ชื่อ - นามสกุล">{employee.name}</Descriptions.Item>
                    <Descriptions.Item label="ชื่อเล่น">{employee.nickname}</Descriptions.Item>
                    <Descriptions.Item label="เบอร์โทรศัพท์">{employee.tel ? employee.tel : '-'}</Descriptions.Item>
                    <Descriptions.Item label="ชื่อบัญชี">{employee.bank_account_name ? employee.bank_account_name : '-'}</Descriptions.Item>
                    <Descriptions.Item label="เลขที่บัญชี">{employee.bank_account ? employee.bank_account : '-'}</Descriptions.Item>
                    <Descriptions.Item label="ธนาคาร">{employee.bank_name ? employee.bank_name : '-'}</Descriptions.Item>
                    <Descriptions.Item label="บริษัท">{employee.company_name}</Descriptions.Item>
                    <Descriptions.Item label="แผนก">{employee.department_name}</Descriptions.Item>
                    <Descriptions.Item label="ตำแหน่ง">{employee.position_name}</Descriptions.Item>
                    <Descriptions.Item label="เงินเดือน">{salaryShow ? `฿ ${salaryShow}` : '-'}</Descriptions.Item>
                    <Descriptions.Item label="วันเริ่มงาน">{employee.work_start ? employee.work_start : '-'}</Descriptions.Item>
                    <Descriptions.Item label="รูปบัตรประชาชน">
                        {imageDownload ?
                            <div style={{ position: 'relative' }}>
                                <img src={imageDownload} alt="IdCard" style={{ width: '100%' }} onClick={() => setPreviewVisible(true)} />
                                <span style={{ position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%,-50%)', color: '#fff', fontSize: '48px' }}>
                                    <EyeOutlined onClick={() => setPreviewVisible(true)} />
                                </span>
                            </div>
                            : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="หมายเหตุ">{employee.remark}</Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title="ข้อมูลระบบ" column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                    <Descriptions.Item label="สถานะ">{statusShow}</Descriptions.Item>
                    <Descriptions.Item label="วันที่สร้าง">{employee.createdAt}</Descriptions.Item>
                    <Descriptions.Item label="สร้างโดย">{employee.created_user_name}</Descriptions.Item>
                    <Descriptions.Item label="วันที่แก้ไข">{employee.updatedAt}</Descriptions.Item>
                    <Descriptions.Item label="แก้ไขโดย">{employee.updated_user_name}</Descriptions.Item>
                </Descriptions>
            </Drawer>
            <Modal
                visible={previewVisible}
                title={'รูปบัตรประชาชน'}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={imageDownload} />
            </Modal>
        </>
    )
}
export default ViewEmployee
