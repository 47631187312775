import React, { FC, useState, useEffect } from 'react'
// import styled from 'styled-components'
// import moment from 'moment'
// import { Modal } from 'reactstrap'
import {
    // Modal,
    Drawer,
    Space,
    Button,
    Form,
    Col,
    Row,
    Input,
    Select,
    DatePicker,
    notification
} from 'antd'
import SendAPI from '../../utils/SendAPI'
import moment from 'moment'


interface CreateExpensesProps {
    expenseLabelList: object
    expenseLabelID?: number
    date?: string
    employeeID: number
    isOpenModal?: boolean
    closeCreateModal: () => void
    onloadData?: () => void
}

interface ExpensesInsertProps {
    expense_label_id: number
    employee_id: number
    amount: number
    date: string
    remark: string
}

const CreateExpenses: FC<CreateExpensesProps> = ({ isOpenModal, date, closeCreateModal, onloadData, expenseLabelList, employeeID, expenseLabelID }) => {
    const [form] = Form.useForm();
    const { sendAPIWithJWT } = SendAPI();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState(false)
    // const patternNumber: any = new RegExp("^([-]?[1-9][0-9]*|0)$")
    const patternNumber: any = new RegExp("^([-]?([0-9]+([.][0-9]*)?|[.][0-9]+))$")
    const dateFormat = 'DD/MM/YYYY';


    const transactionCreate = async (values: ExpensesInsertProps) => {
        const dataResponse: any = await sendAPIWithJWT({
            path: `/finances`,
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (dataResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: dataResponse.data?.message
            });
            setBtnLoading(false)
            closeCreateModal()
            if (onloadData) {
                onloadData()
            }
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
            if (onloadData) {
                onloadData()
            }
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: ExpensesInsertProps) => {
                setBtnLoading(true)
                values.employee_id = Number(employeeID)
                values.amount = Number(values.amount)
                values.date = moment(values.date).format('YYYY-MM-DD')
                transactionCreate(values)
            })
            .catch((errorInfo) => { });
    };

    const getExpenseLabelOption = Object.values(expenseLabelList).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    useEffect(() => {
        if (isOpenModal) {
            form.resetFields();
            form.setFieldsValue({
                'date': moment(date).startOf('month'),
                'expense_label_id': expenseLabelID ? expenseLabelID : null
            });
        }
    }, [form, isOpenModal, date, expenseLabelID]);

    return (
        <Drawer
            title={'ค่าใช้จ่าย'}
            onClose={closeCreateModal}
            visible={isOpenModal}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={closeCreateModal} size="large">ยกเลิก</Button>
                    <Button
                        onClick={handleFormSubmit}
                        type="primary"
                        loading={btnLoading}
                        size="large"
                        danger
                    >
                        <i className="far fa-save me-2"></i>
                        บันทึก
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                name="passwordUserForm"
                layout="vertical"
                hideRequiredMark
            >

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="expense_label_id"
                            label="รายการค่าใช้จ่าย"
                            rules={[{ required: true, message: 'กรุณาเลือกรายการค่าใช้จ่าย' }]}
                            hasFeedback>
                            <Select
                                placeholder="เลือกรายการค่าใช้จ่าย"
                                disabled={expenseLabelID ? true : false}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getExpenseLabelOption}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="amount"
                            label="ค่าใช้จ่าย (บาท)"
                            rules={[
                                { required: true, message: 'กรุณากรอกค่าใช้จ่าย (บาท)' },
                                { pattern: patternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                            ]}
                            hasFeedback
                            extra="กรุณากรอกเป็นจำนวนเต็มบวกหรือจำนวนเต็มลบ เช่น 10 หรือ -10"
                        >
                            <Input placeholder="ค่าใช้จ่าย (บาท)" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="date"
                            label="วันที่"
                            rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]}
                        >
                            <DatePicker format={dateFormat} placeholder="วันที่" className="date-picker-employee" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="remark"
                            label="หมายเหตุ"
                            rules={[{ required: false, message: 'กรุณากรอกหมายเหตุ' }]}
                        >
                            <Input.TextArea rows={3} placeholder="หมายเหตุ" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="text-end">
                    <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-save me-2"></i>บันทึก</button>
                    <button type="button" className="btn btn-light me-3" onClick={() => closeCreateModal()}>ยกเลิก</button>
                </div>
            </Form>
        </Drawer>
    )
}
export default CreateExpenses

// const DatePickerFloating = styled.div`
//     label {
//         transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
//     }

//     .ant-picker {
//         width: 100%;
//         padding: 0 !important;
//     }

//     .ant-picker-input input {
//         padding: 1.625rem 0 0.625rem 0.75rem;
//     }

//     .ant-picker-suffix {
//         font-size: 20px;
//         padding-right: 11px;
//     }
// `