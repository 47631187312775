import React, { FC, useEffect, useState } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Select,
    Upload,
    Space,
    notification,
    DatePicker,
    Modal
} from 'antd'
import SendAPI from '../../utils/SendAPI'
import moment from 'moment'
import { LoadingOutlined, PlusOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'

interface CreateEmployeeProps {
    bankList: object
    companyList: object
    departmentList: object
    isOpenCreateDrawer: boolean
    closeCreateDrawer: () => void
    onloadData: () => void
}
interface CreateEmployeeInsertProps {
    nickname: string
    tel?: string
    name: string
    bank_id?: number
    bank_account_name?: string
    bank_account?: string
    position_id: number
    salary?: number
    status: string
    work_start?: string
    remark?: string
    identification_card_image?: string
}

const CreateEmployee: FC<CreateEmployeeProps> = ({ isOpenCreateDrawer, closeCreateDrawer, onloadData, bankList, companyList, departmentList }) => {
    const nameTitle = "เพิ่มพนักงาน"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option, OptGroup } = Select;
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [previewVisible, setPreviewVisible] = useState(false)

    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    const [imageUrl, setImageUrl] = useState('')
    const [filenameImage, setFilenameImage] = useState('')
    const dateFormat = 'DD/MM/YYYY';
    const patternNumberBank: any = new RegExp("^([0-9][0-9]*|0)$")
    const patternNumber: any = new RegExp("^([-]?([0-9]+([.][0-9]*)?|[.][0-9]+))$")

    const insertEmployee = async (values: CreateEmployeeInsertProps) => {
        const createResponse: any = await sendAPIWithJWT({
            path: "/employees",
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (createResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: createResponse.data?.message
            });
            setFilenameImage('')
            setImageUrl('')
            setBtnLoading(false)
            closeCreateDrawer()
            onloadData()
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: createResponse.message ? createResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: CreateEmployeeInsertProps) => {
                setBtnLoading(true)
                values.salary = Number(values.salary)
                values.work_start = values.work_start ? moment(values.work_start).format('YYYY-MM-DD') : ''
                values.identification_card_image = filenameImage ? filenameImage : ''
                insertEmployee(values)

            })
            .catch((errorInfo) => { });
    };

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const customRequestUploadPicture = async (info: any) => {
        await getBase64(info.file, (ImageBase64: any) =>
            sendImage(ImageBase64)
        );
    }

    const sendImage = async (ImageBase64: any) => {
        const uploadImage: any = await sendAPIWithJWT({
            path: "/employees/idcard/upload",
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: {
                "image_base64": ImageBase64
            }
        });
        if (uploadImage.status === 200) {
            notification.success({
                message: 'Success',
                description: uploadImage.data?.message
            });
            setImageUrl(ImageBase64)
            setFilenameImage(uploadImage.data.filename)
            setLoadingImage(false)
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: uploadImage.message ? uploadImage.message : "เกิดข้อผิดพลาด"
            });
            setImageUrl('')
            setFilenameImage('')
            setLoadingImage(false)
        }
    }

    const handleUploadPicture = async (info: any) => {
        if (info.file.status === 'uploading') {
            setImageUrl('')
            setFilenameImage('')
            setLoadingImage(true)
        }
        if (info.file.status === 'done') {
            notification.error({
                message: 'Success',
                description: `${info.file.name} file uploaded successfully`
            });
        } else if (info.file.status === 'error') {
            notification.error({
                message: 'Error',
                description: `${info.file.name} file upload failed.`
            });
        }
    }

    const beforeUploadPicture = async (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            notification.error({
                message: 'Warning',
                description: `You can only upload JPG/PNG file!`
            });
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            notification.error({
                message: 'Warning',
                description: `Image must smaller than 2MB!`
            });
        }
        return isJpgOrPng && isLt2M;
    }

    const handleRemovePicture = async () => {
        setLoadingImage(true)
        const deleteImg: any = await sendAPIWithJWT({
            path: `/employees/idcard/delete?filename=${filenameImage}`,
            method: "delete"
        });
        if (deleteImg.status === 200) {
            setFilenameImage('')
            setImageUrl('');
        }
        setLoadingImage(false)
    }

    useEffect(() => {
        if (form && isOpenCreateDrawer) {
            form.resetFields();
            form.setFieldsValue({ status: 'enable' })
            setFilenameImage('')
            // setImageUrl('')
        }
    }, [form, isOpenCreateDrawer]);

    const getBankOption = Object.values(bankList).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    const getCompanyOption = Object.values(companyList).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    const getDepartmentOption = Object.values(departmentList).map((item: any) => {
        return (
            <OptGroup label={item.name} key={item.id}>
                {item.positions.map((option: any) => (
                    <Option key={option.id} value={option.id}>{option.name}</Option>
                ))}
            </OptGroup>
        )
    })

    const uploadButton = (
        <div>
            {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const closeCreateDrawerAndDeletePic = async () => {
        if (filenameImage) {
            await handleRemovePicture()
        }
        closeCreateDrawer()
    }

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeCreateDrawerAndDeletePic}
                visible={isOpenCreateDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateDrawerAndDeletePic} size="large">ยกเลิก</Button>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large"
                            danger
                        >
                            <i className="far fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="CreateEmployee"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="ชื่อ - นามสกุล"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ - นามสกุล' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อ - นามสกุล" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="nickname"
                                label="ชื่อเล่น"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อเล่น' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อเล่น" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="tel"
                                label="เบอร์โทรศัพท์"
                                rules={[{ required: false, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
                                hasFeedback
                            >
                                <Input placeholder="เบอร์โทรศัพท์" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_account_name"
                                label="ชื่อบัญชี"
                                rules={[{ required: false, message: 'กรุณากรอกชื่อบัญชี' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อบัญชี" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_account"
                                label="เลขที่บัญชี"
                                rules={[
                                    { required: false, message: 'กรุณากรอกเลขที่บัญชี' },
                                    { pattern: patternNumberBank, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback
                            >
                                <Input placeholder="เลขที่บัญชี" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_id"
                                label="ธนาคาร"
                                rules={[{ required: false, message: 'กรุณาเลือกธนาคาร' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือกธนาคาร"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getBankOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="company_id"
                                label="บริษัท"
                                rules={[{ required: false, message: 'กรุณาเลือกบริษัท' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือกบริษัท"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getCompanyOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="position_id"
                                label="แผนก/ตำแหน่ง"
                                rules={[{ required: true, message: 'กรุณาเลือกตำแหน่ง' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือกตำแหน่ง"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        if (option.children) {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                        } else if (option.label) {
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                        }
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }}
                                >
                                    {getDepartmentOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="salary"
                                label="เงินเดือน"
                                rules={[
                                    { required: false, message: 'กรุณากรอกเงินเดือน' },
                                    { pattern: patternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback
                            >
                                <Input placeholder="เงินเดือน" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="สถานะ"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                                hasFeedback
                            >
                                <Select placeholder="เลือกสถานะ">
                                    <Option value="enable">เปิด</Option>
                                    <Option value="disable">ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="work_start"
                                label="วันเริ่มงาน"
                                rules={[{ required: false, message: 'กรุณาเลือกวันเริ่มงาน' }]}
                            >
                                <DatePicker format={dateFormat} placeholder="วันเริ่มงาน" className="date-picker-employee" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <span>รูปบัตรประชาชน</span><br />
                            <Space>
                                <Upload
                                    name="identification_card_image"
                                    listType="picture-card"
                                    className="idcard-uploader"
                                    showUploadList={false}
                                    customRequest={customRequestUploadPicture}
                                    beforeUpload={beforeUploadPicture}
                                    onChange={handleUploadPicture}
                                    progress={{
                                        strokeColor: {
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        },
                                        strokeWidth: 3,
                                        format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
                                    }}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="IdCard" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                                {imageUrl &&
                                    <>
                                        <Button type="primary" onClick={() => setPreviewVisible(true)} icon={<EyeOutlined />} shape="circle" ></Button>
                                        <Button type="primary" onClick={() => handleRemovePicture()} icon={<DeleteOutlined />} shape="circle" danger></Button>
                                    </>
                                }
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="remark"
                                label="หมายเหตุ"
                                rules={[{ required: false, message: 'กรุณากรอกหมายเหตุ' }]}
                            >
                                <Input.TextArea rows={3} placeholder="หมายเหตุ" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-save me-2"></i> บันทึก</button>
                        <button type="button" className="btn btn-light me-3" onClick={() => closeCreateDrawerAndDeletePic()}>ยกเลิก</button>
                    </div>
                </Form>
            </Drawer>
            <Modal
                visible={previewVisible}
                title={'รูปบัตรประชาชน'}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={imageUrl} />
            </Modal>
        </>
    )
}
export default CreateEmployee
