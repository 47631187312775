import React, { Fragment } from 'react'
import ViewProfile from '../../components/Profile/View'
// import SendAPI from '../../utils/SendAPI'
// import useAuth from '../../utils/Auth'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

const Profile = () => {
    // const { getMe } = useAuth();

    const { allows, data } = useSelector((state: ApplicationState) => state.auth)

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <ViewProfile
                    data={data}
                    allows={allows}
                />
            </div>
        </Fragment>
    )
}

export default Profile