import React, { FC, useEffect, useState } from 'react'
import { 
    Drawer, 
    Button, 
    Space,
    Divider, 
    Descriptions,} from 'antd';
// import SyntaxHighlighter from 'react-syntax-highlighter'
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'

interface ViewAccountProps {
    accounts: any
	closeViewDrawer: () => void
	isOpenViewDrawer: boolean
	swapDrawerEdit: () => void
}

const ViewAccount: FC<ViewAccountProps> = ({ closeViewDrawer, isOpenViewDrawer, swapDrawerEdit, accounts }) => {
    const nameTitle = "ข้อมูลบัญชี"
    const [statusShow, setStatusShow] = useState('')
    useEffect(() => {
        const statusList: any = [{status: 'enable', name: 'เปิด'},{status: 'disable', name: 'ปิด'}]
        if (accounts) {
            setStatusShow(statusList.find((item: any) => {return item.status === accounts.status}).name)
        }
    }, [accounts])
	return (
        <>
        <Drawer
            title={nameTitle}
            onClose={closeViewDrawer}
            visible={isOpenViewDrawer}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
            <Space>
                <Button onClick={closeViewDrawer} size="large">ปิด</Button>
                <Button 
                    onClick={swapDrawerEdit} 
                    type="primary" 
                    size="large"
                    danger
                    >
                    <i className="far fa-edit me-2"></i>
                    แก้ไข
                </Button>
            </Space>
            }
        >
            <Descriptions title={nameTitle} column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="ชื่อบัญชี">{accounts.bank_name}</Descriptions.Item>
                <Descriptions.Item label="เลขที่บัญชี">{accounts.bank_account}</Descriptions.Item>
                {/* <Descriptions.Item label="Encrypt" >
                    <SyntaxHighlighter 
                        language="json"
                        style={docco}
                        wrapLines={true}
                        wrapLongLines={true}
                        lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                        className={"syntax-highlighter"}
                    >
                    {accounts.encrypt}
                    </SyntaxHighlighter>
                </Descriptions.Item> */}
            </Descriptions>
            <Descriptions title={'SCB API'} column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="Device ID">{accounts.device_id}</Descriptions.Item>
                <Descriptions.Item label="License">{accounts.license}</Descriptions.Item>
                <Descriptions.Item label="PIN">{accounts.pin}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="ข้อมูลระบบ" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="สถานะ">{statusShow}</Descriptions.Item>
                <Descriptions.Item label="วันที่สร้าง">{accounts.createdAt}</Descriptions.Item>
                <Descriptions.Item label="สร้างโดย">{accounts.created_user_name}</Descriptions.Item>
                <Descriptions.Item label="วันที่แก้ไข">{accounts.updatedAt}</Descriptions.Item>
                <Descriptions.Item label="แก้ไขโดย">{accounts.updated_user_name}</Descriptions.Item>
            </Descriptions>
            
        </Drawer>
        </>
	)
}
export default ViewAccount
