import React, { FC, useEffect, useState } from 'react'
import { 
    Drawer, 
    Button, 
    Space,
    Divider, 
    Descriptions,} from 'antd';

interface ViewDataProps {
    listData: any
	closeViewData: () => void
	isOpenViewData: boolean
}

const ViewExpenses: FC<ViewDataProps> = ({ closeViewData, isOpenViewData, listData }) => {
    const nameTitle = "ข้อมูลสรุปเงินเดือน"
    const [amount, setAmount] = useState<any>()
    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    useEffect(() => {
        const amountFormat = (amount: number) => {
            if (amount >= 0) {
                return (<span style={{color: '#139B82'}}>+ ฿ {numberWithCommas(amount)}</span>)
            }else if(amount < 0){
                return (<span style={{color: '#DF4B4A'}}>- ฿ {numberWithCommas(Math.abs(amount))}</span>)
            }else{
                return (<span>฿ {numberWithCommas(amount)}</span>)
            }
        }
    
        if (listData) {
            setAmount(amountFormat(listData.amount))
        }
    }, [listData, isOpenViewData])
	return (
        <>
        <Drawer
            title={nameTitle}
            onClose={closeViewData}
            visible={isOpenViewData}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
            <Space>
                <Button onClick={closeViewData} size="large">ปิด</Button>
            </Space>
            }
        >
            <Descriptions title="ข้อมูลพนักงาน" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="ชื่อ - นามสกุล">{listData.employee_name}</Descriptions.Item>
                <Descriptions.Item label="ชื่อเล่น">{listData.employee_nickname}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="ข้อมูลค่าใช้จ่าย" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="รายการค่าใช้จ่าย">{listData.expense_label_name ? listData.expense_label_name : '-'}</Descriptions.Item>
                <Descriptions.Item label="ชื่อค่าใช้จ่าย">{amount}</Descriptions.Item>
                <Descriptions.Item label="วันที่">{listData.date}</Descriptions.Item>
                <Descriptions.Item label="หมายเหตุ">{listData?.remark}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="ข้อมูลระบบ" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="วันที่สร้าง">{listData.createdAt}</Descriptions.Item>
                <Descriptions.Item label="สร้างโดย">{listData.created_user_name}</Descriptions.Item>
                <Descriptions.Item label="วันที่แก้ไข">{listData.updatedAt}</Descriptions.Item>
                <Descriptions.Item label="แก้ไขโดย">{listData.updated_user_name}</Descriptions.Item>
            </Descriptions>
            
        </Drawer>
        </>
	)
}
export default ViewExpenses
