import React, { FC } from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Skeleton } from 'antd'

interface AmountCardProps {
    colorTheme?: string
    icon: string
    iconColorRgb: string
    textColorRgb?: string
    buttonText?: string
    buttonLink?: string
    amount: string
    amountType: string
    amountTime?: string
    numList?: string
    loading: boolean
}

const AmountCard: FC<AmountCardProps> = ({ icon, iconColorRgb, colorTheme, textColorRgb, buttonText, buttonLink, amount, amountType, amountTime, numList, loading }) => {
    return (
        <Card background={colorTheme ?? '#fff'} className={`mb-4 ${buttonLink && buttonText ? 'flex-wrap' : ''}`}>
            <IconWrapper iconColor={iconColorRgb ?? '0, 0, 0'}>
                <i className={icon}></i>
            </IconWrapper>
            <TextWrapper textColor={textColorRgb ?? '0, 0, 0'}>
                {loading
                    ? 
                        <Skeleton.Button active size={'small'} />
                    :
                    <>
                        <h2>฿ {amount}</h2>
                        <p>{amountType} {numList ? `${numList} รายการ` : ''}</p>
                        {amountTime ? <span>{amountTime}</span> : ''}
                    </>
                }
            </TextWrapper>
            {buttonLink && buttonText ?
                <LinkWrapper linkColor={'#5952C0'}>
                    <Link className="btn btn-primary btn-lg" to={buttonLink ?? '/'}>
                        {buttonText}
                    </Link>
                </LinkWrapper>
            : ''}
        </Card>
    )
}

export default AmountCard

const Card = styled.div<{background: string}>`
    display: flex;
    background: ${props => props.background};

    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.02);
    border-radius: 6px;
    padding: 24px;

    @media only screen and (min-width: 768px) {
        padding: 32px;
    }
`;
const IconWrapper = styled.div<{iconColor: string}>`
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(${props => props.iconColor}, 0.07);
    color: rgb(${props => props.iconColor});
    border-radius: 6px;
    font-size: 20px;
    margin-right: 24px;

    @media only screen and (min-width: 768px) {
        margin-right: 32px;
    }
`;

const TextWrapper = styled.div<{textColor: string}>`
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
        color: rgb(${props => props.textColor});
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 4px;
    }

    p {
        font-size: 16px;
        color: rgba(${props => props.textColor}, 0.5);
        margin: 0;
    }
    span {
        font-size: 12px;
        color: rgba(${props => props.textColor}, 0.5);
        margin: 0;
    }

`;

const LinkWrapper = styled.div<{linkColor: string}>`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;

    .btn {
        width: 100%;
        color: ${props => props.linkColor} !important;;
        background: #fff !important;
        border-color: #fff !important;
        transition: all 0.25s ease;

        &:hover {
            transform: scale(1.05);
        }
    }

    @media only screen and (min-width: 992px) {
        margin-left: auto;
        width: auto;
        margin-top: 0;

        .btn {
            width: auto;
        }
    }
`;