import React, { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import DataTable from 'react-data-table-component';
import CreateTransaction from '../../components/Transfer/CreateTransaction'
import CreateEmployee from '../../components/Employees/CreateEmployee'
import EditEmployee from '../../components/Employees/EditEmployee'
import ViewEmployee from '../../components/Employees/ViewEmployee'
import SalarySummaryEmployee from '../../components/Employees/SalarySummaryEmployee'
import PayrollAdvanceEmployee from '../../components/Employees/PayrollAdvanceEmployee'
import { Input, Table, Switch, notification, Space, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2'
import SendAPI from '../../utils/SendAPI'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'

interface EmployeeTableProps {
    list: any
    banks: object
    departments: object
    accounts: object
    companys: object
    loading: boolean
    onloadData: () => void
}

const EmployeeTable: FC<EmployeeTableProps> = ({ list, banks, departments, accounts, companys, loading, onloadData }) => {
    const { sendAPIWithJWT } = SendAPI();
    const [filterText, setFilterText] = useState('')
    const [filterCompany, setFilterCompany] = useState('')
    const [filterDepartment, setFilterDepartment] = useState('')
    const [filterPosition, setFilterPosition] = useState('')
    const { Option } = Select;

    const [dataOjects, setDataOjects] = useState<any>([])
    const [positionList, setPositionList] = useState<any>([])

    const [createTransactionDrawer, setCreateTransactionDrawer] = useState(false)
    const [createEmployeeDrawer, setCreateEmployeeDrawer] = useState(false)
    const [editEmployeeDrawer, setEditEmployeeDrawer] = useState(false)
    const [viewEmployeeDrawer, setViewEmployeeDrawer] = useState(false)
    const [salarySummaryDrawer, setSalarySummaryDrawer] = useState(false)
    const [payrollDrawer, setPayrollDrawer] = useState(false)
    const toggleCreateTransaction = () => setCreateTransactionDrawer(!createTransactionDrawer)
    const toggleCreateEmployee = () => setCreateEmployeeDrawer(!createEmployeeDrawer)
    const toggleEditEmployee = () => setEditEmployeeDrawer(!editEmployeeDrawer)
    const toggleViewEmployee = () => setViewEmployeeDrawer(!viewEmployeeDrawer)
    const toggleSalarySummary = () => setSalarySummaryDrawer(!salarySummaryDrawer)
    const togglePayroll = () => setPayrollDrawer(!payrollDrawer)

    const [switchLoading, setSwitchLoading] = useState(false)
    const [employee, setEmployee] = useState('')
    const [employeeId, setEmployeeId] = useState(0)
    const [employeeAmount, setEmployeeAmount] = useState(0)
    const handleEditEmployee = (object: any) => setEmployee(object)
    const handleDefaultTransfer = (id: number, amount: number) => {
        setEmployeeId(id)
        setEmployeeAmount(amount)
    }
    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }


    const handleDeleteEmployee = (id: number) => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: "คุณต้องการลบรายการนี้ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dataResponse: any = await sendAPIWithJWT({
                    path: `/employees/${id}`,
                    method: "delete",
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    onloadData()
                } else {
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
            }
        })
    };
    const handleStatusEmployee = async (status: boolean, id: number) => {
        if (id) {
            let statusUpdate = status ? "enable" : "disable"
            const dataResponse: any = await sendAPIWithJWT({
                path: `/employees/${id}`,
                method: "patch",
                headers: { 'Content-Type': 'application/json' },
                data: {
                    "status": statusUpdate
                }
            });
            if (dataResponse.status === 200) {
                notification.success({
                    message: 'Success',
                    description: dataResponse.data?.message
                });
                list[list.findIndex((el: any) => el.id === id)].status = dataResponse.data?.data.status
            } else {
                notification.error({
                    message: 'Error',
                    description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
                });
            }
            setSwitchLoading(false)
        }
    };

    const columns = [
        {
            title: '#',
            render: (row: any) => row.serial,
            width: '62px',
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => a.serial - b.serial,
        },
        {
            title: 'ชื่อ',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div
                            className="btn-link-view"
                            onClick={() => {
                                handleEditEmployee(row)
                                toggleViewEmployee()
                            }}
                        >
                            {row.nickname}
                        </div>
                        <small className="text-muted">{row.name}</small>
                    </TextNoWrap>
                )
            },
            width: '150px',
        },
        {
            title: 'บัญชีธนาคาร',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.bank_account_name}</div>
                        {row.bank_name && <div><small className="text-muted">{row.bank_name}</small></div>}
                        <div><small className="text-muted">{row.bank_account ? row.bank_account : '-'}</small></div>
                    </TextNoWrap>
                )
            },
            width: '140px',
        },
        {
            title: 'บริษัท',
            render: (row: any) => {
                return (
                    <div>{row.company_name}</div>
                )
            },
            width: '140px',
        },
        {
            title: 'แผนก/ตำแหน่ง',
            render: (row: any) => {
                return (
                    <>
                        <div>{row.department_name}</div>
                        <small className="text-muted">{row.position_name}</small>
                    </>
                )
            },
            width: '150px',
        },
        {
            title: 'เงินเดือน',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.salary ? '฿ ' + numberWithCommas(row.salary) : '-'}</div>
                    </TextNoWrap>
                )
            },
            width: '140px',
        },
        {
            title: 'โอนเงินล่าสุด',
            render: (row: any) => {
                return (
                    <>
                        <div>{row.last_transfer_update_at ? row.last_transfer_update_at : '-'}</div>
                        {row.last_transfer_amount ? <Badge className="d-block badge">{`฿ ${numberWithCommas(row.last_transfer_amount)}`}</Badge> : null}
                    </>
                )
            },
            width: '120px',
        },
        {
            title: 'สถานะ',
            width: '90px',
            render: (row: any) => {
                let switchStatus = (row.status === "enable") ? true : false
                return (
                    <>
                        <Switch
                            checkedChildren="เปิด"
                            unCheckedChildren="ปิด"
                            checked={switchStatus}
                            loading={switchLoading}
                            onChange={(e) => {
                                setSwitchLoading(true)
                                handleStatusEmployee(e, row.id)
                            }} />
                    </>
                )
            },
        },
        {
            title: 'วันที่สร้าง',
            render: (row: any) => row.createdAt,
            width: '140px',
        },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <Space size={0}>
                        <button
                            className="btn btn-info btn-sm text-white me-2"
                            onClick={() => {
                                handleEditEmployee(row)
                                toggleSalarySummary()
                            }}
                        >
                            สรุป
                        </button>
                        <button
                            className="btn btn-secondary btn-sm text-white me-2"
                            onClick={() => {
                                handleEditEmployee(row)
                                togglePayroll()
                            }}
                        >
                            เบิก
                        </button>
                        <button
                            className="btn btn-success btn-sm text-white me-2"
                            onClick={() => {
                                handleDefaultTransfer(row.id, 0)
                                handleEditEmployee(row)
                                toggleCreateTransaction()
                            }}
                            disabled={row.bank_account_name && row.bank_account && row.bank_name ? false : true}
                        >
                            โอนเงิน
                        </button>
                        <button
                            className="btn btn-warning btn-sm text-white me-2"
                            onClick={() => {
                                handleEditEmployee(row)
                                toggleEditEmployee()
                            }}
                        >
                            แก้ไข</button>
                        <button className="btn btn-danger btn-sm" onClick={() => { handleDeleteEmployee(row.id) }}>ลบ</button>
                    </Space>
                )
            },
        }
    ]

    useEffect(() => {
        if (Object.keys(list).length > 0) {
            let filteredItems = (list as any).filter(
                (item: any) => (
                    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.position_name && item.position_name.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.department_name && item.department_name.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );

            if (filterCompany) {
                filteredItems = (filteredItems as any).filter(
                    (item: any) => item.company_id === filterCompany,
                );
            }

            if (filterDepartment) {
                setPositionList([])
                filteredItems = (filteredItems as any).filter(
                    (item: any) => item.department_id === filterDepartment,
                );
                const positionList = (departments as any).find(
                    (item: any) => item.id === filterDepartment,
                );
                setPositionList(positionList?.positions)
            }
            if (filterPosition) {
                filteredItems = (filteredItems as any).filter(
                    (item: any) => item.position_id === filterPosition,
                );
            }
            filteredItems.forEach((item: any, index: number) => { item.serial = index + 1; });
            setDataOjects(filteredItems)
        }
    }, [list, filterText, filterCompany, filterDepartment, filterPosition, setDataOjects, departments])



    const getCompanyOption = Object.values(companys).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    const getDepartmentOption = Object.values(departments).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    const getPositionsOption = Object.values(positionList).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    return (
        <>
            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <div className="card-head">
                    <div className="row mb-md-3 align-items-md-center">
                        <h2 className="col-12 col-md-auto mb-3 mb-md-0">พนักงาน</h2>
                        <div className="col-6 col-md-auto ms-md-auto px-md-0">
                            <button
                                className="btn btn-primary mb-3 mb-md-0 me-md-3"
                                onClick={() => toggleCreateEmployee()}
                            >
                                <i className="far fa-plus me-3"></i>
                                <span>เพิ่มพนักงาน</span>
                            </button>
                        </div>
                        <div className="col-6 col-md-auto ps-md-0">
                            <Link
                                to={'/employees/summary'}
                                target={'_blank'}
                                className="btn btn-info"
                            >
                                <i className="fas fa-file-chart-line me-3"></i>
                                <span>สรุปเงินเดือน</span>
                            </Link>
                        </div>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <label>บริษัท : </label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterCompany}
                                onSelect={(value: any) => {
                                    setFilterCompany(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">ทั้งหมด</Option>
                                {getCompanyOption}
                            </Select>
                        </div>
                        <div className="filter-group">
                            <label>แผนก : </label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterDepartment}
                                onSelect={(value: any) => {
                                    setFilterPosition("")
                                    setPositionList([])
                                    setFilterDepartment(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">ทั้งหมด</Option>
                                {getDepartmentOption}
                            </Select>
                        </div>
                        <div className="filter-group">
                            <label>ตำแหน่ง : </label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterPosition}
                                onSelect={(value: any) => {
                                    setFilterPosition(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">ทั้งหมด</Option>
                                {getPositionsOption}
                            </Select>
                        </div>
                        <div className="filter-group">
                            <Input
                                size="large"
                                addonBefore={<SearchOutlined style={{ marginBottom: '6px' }} />}
                                allowClear
                                placeholder="ค้นหา"
                                value={filterText ?? ''}
                                onChange={(e: any) => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns as any}
                    dataSource={dataOjects}
                    rowKey={record => record.id}
                    loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                    onChange={ScrollToTopPagination}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50', '80', '100'],
                        position: ['topRight', 'bottomRight'],
                        locale: { items_per_page: " รายการ" }
                    }}
                />
            </ListTableCard>
            <CreateTransaction
                employeeList={list}
                accountList={accounts}
                employeeId={employeeId}
                amount={employeeAmount}
                isOpenCreateData={createTransactionDrawer}
                closeCreateData={() => toggleCreateTransaction()}
            />
            <CreateEmployee
                bankList={banks as any}
                companyList={companys as any}
                departmentList={departments as any}
                isOpenCreateDrawer={createEmployeeDrawer}
                closeCreateDrawer={() => toggleCreateEmployee()}
                onloadData={() => onloadData()}
            />
            <EditEmployee
                employee={employee as any}
                bankList={banks as any}
                companyList={companys as any}
                departmentList={departments as any}
                isOpenEditDrawer={editEmployeeDrawer}
                closeEditDrawer={() => toggleEditEmployee()}
                onloadData={() => onloadData()}
            />
            <ViewEmployee
                employee={employee as any}
                isOpenViewDrawer={viewEmployeeDrawer}
                closeViewDrawer={() => toggleViewEmployee()}
                swapDrawerEdit={() => {
                    toggleViewEmployee()
                    toggleEditEmployee()
                }}
            />
            <SalarySummaryEmployee
                employee={employee as any}
                employeeList={list as any}
                isOpenSalarySummaryDrawer={salarySummaryDrawer}
                closeSalarySummaryDrawer={() => toggleSalarySummary()}
                onloadData={() => onloadData()}
            />

            <PayrollAdvanceEmployee
                employee={employee as any}
                employeeList={list as any}
                isOpenDrawer={payrollDrawer}
                closeDrawer={() => togglePayroll()}
                onloadData={() => onloadData()}
            />
        </>
    );
}

export default EmployeeTable

const ListTableCard = styled.div`
    background: #fff;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`

const Badge = styled.small`
	font-weight: normal;
	color: rgb(19, 155, 130);
    background: rgba(19, 155, 130, 0.1);
`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`