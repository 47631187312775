import React, { Fragment, useEffect, useState } from 'react'
// import { useParams } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom';
// import MonthPicker from '../../components/Elements/MonthPicker'
import SummaryAmountTable from '../../components/EmployeeSummary/SummaryAmountTable'
// import SalaryMockup from '../../mockup-json/SalaryMockup.json'
// import EmployeesMockup from '../../mockup-json/EmployeesMockup.json'
import SendAPI from '../../utils/SendAPI'
import { DatePicker, Space } from 'antd'
import moment from 'moment'

// interface EmployeeSummaryParams {
// 	employeeID: string,
// }

const EmployeeSummary = () => {
    const { employeeID } = useParams()
    const history = useNavigate()
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const monthFormat = 'MM/YYYY';
    const [monthSearch, setMonthSearch] = useState(moment())
    const [dataTransections, setDataTransections] = useState<any>([])
    const [dataEmployees, setDataEmployees] = useState<any>([])
    const [dataAccounts, setDataAccounts] = useState<any>([])
    const [dataExpenseLabels, setDataExpenseLabels] = useState<any>([])
    const getTransections = async (mount: any) => {
        const startOfMonth = moment(mount).startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment(mount).endOf('month').format('YYYY-MM-DD');
        const dataResponse: any = await sendAPIWithJWT({
            path : `/finances?employee_id=${employeeID}&sdate=${startOfMonth}&edate=${endOfMonth}`,
            method : "get",
        });
        // expense_label_id=1
        if (dataResponse.status === 200) {
            setDataTransections(dataResponse.data)
            // setDataEmployees(dataResponse.data?.employee)
        }
        setLoading(false)
        return dataResponse
    }

    const getEmployees = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/employees",
            method : "get",
        });
        
        if (dataResponse.status === 200) {
            setDataEmployees(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const getAccounts = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/accounts?status=enable",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataAccounts(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const getExpenseLabels = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/expense-labels?status=enable",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataExpenseLabels(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }
    
    const onSearch = () => {
        getTransections(monthSearch)
    }

    const changeMonthSearch = (date: any, dateString: string) => {
        setMonthSearch(date)
    }
    
    useEffect(() => {
        onSearch()
        getEmployees()
        getAccounts()
        getExpenseLabels()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5 card-head d-md-flex agile-items-center mb-3">
                <div className="d-flex flex-column flex-md-row mb-3 mb-md-0">
                    {/* <MonthPicker onSearch={() => onSearch()} /> */}
                    <div className="d-lg-flex mb-4">
                        <div className="d-flex mb-3 mb-lg-0">
                            <Space
                                direction="vertical"
                                size={12}
                            >
                                <DatePicker
                                    defaultValue={moment(monthSearch)}
                                    format={monthFormat} picker="month"
                                    onChange={changeMonthSearch}
                                />
                            </Space>
                            <button type="button" className="btn btn-outline-primary ms-3" onClick={onSearch}>ค้นหา</button>
                        </div>
                    </div>
                </div>
                <div className="ms-md-auto">
                    <button className="btn btn-primary" onClick={() => history('/employees')} >
                        <i className="fas fa-chevron-left me-3"></i>
                        <span>ย้อนกลับ</span>
                    </button>
                </div>
            </div>
            <SummaryAmountTable
                list={dataTransections}
                employeeID={employeeID}
                employee={dataEmployees}
                accounts={dataAccounts}
                expenseLabels={dataExpenseLabels}
                loading={loading} 
                onloadData={() => onSearch()} 
            />
        </Fragment>
    )
}

export default EmployeeSummary