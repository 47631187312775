import React, { FC, useEffect, useState } from 'react'
import {
    // Modal,
    Form,
    Col,
    Row,
    Input,
    notification
} from 'antd';
import { Modal } from 'reactstrap'
import SendAPI from '../../utils/SendAPI'

interface PasswordUserProps {
    user: any
    closePasswordDrawer: () => void
    isOpenPasswordDrawer: boolean
}
interface PasswordUserInsertProps {
    id: number
    password_old: string
    password: string
}
const PasswordUser: FC<PasswordUserProps> = ({ closePasswordDrawer, isOpenPasswordDrawer, user }) => {
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState(false)

    const changePassword = async (values: PasswordUserInsertProps) => {
        const dataResponse: any = await sendAPIWithJWT({
            path: `/auth/password`,
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (dataResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: dataResponse.data?.message
            });
            setBtnLoading(false)
            closePasswordDrawer()
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
            form.resetFields();
            // closePasswordDrawer()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: PasswordUserInsertProps) => {
                setBtnLoading(true)
                changePassword(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (isOpenPasswordDrawer) {
            form.resetFields();
        }
    }, [form, isOpenPasswordDrawer]);

    return (
        <>
            <Modal isOpen={isOpenPasswordDrawer} className="modal-dialog-centered" fade={true} toggle={() => closePasswordDrawer()}>
                <div className="modal-body p-lg-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h3 className="mb-0"><strong>{`เปลี่ยนรหัสผ่าน : ${user.username}`}</strong></h3>
                        <div className="modal-close">
                            <i className="far fa-times" onClick={() => closePasswordDrawer()}></i>
                        </div>
                    </div>
                    <Form
                        form={form}
                        name="passwordUserForm"
                        layout="vertical"
                        hideRequiredMark
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="password_old"
                                    label="รหัสผ่านเดิม"
                                    rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}

                                >
                                    <Input.Password placeholder="รหัสผ่านเดิม" autoComplete="old-password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="password"
                                    label="รหัสผ่านใหม่"
                                    rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}

                                >
                                    <Input.Password placeholder="รหัสผ่านใหม่" autoComplete="new-password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <button type="button" className="btn btn-light me-3" onClick={() => closePasswordDrawer()}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}>ยืนยัน</button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
export default PasswordUser
