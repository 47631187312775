import React, { Fragment, useEffect, useState } from 'react'
import ExpenseLabel from '../../components/ExpenseLabels/Table'
import SendAPI from '../../utils/SendAPI'

const ExpenseLabels = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataExpenseLabels, setDataExpenseLabels] = useState<any>([])
    const getExpenseLabels = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/expense-labels",
            method : "get",
        });
        if (dataResponse.status === 200) {
            dataResponse.data?.data.shift();
            setDataExpenseLabels(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }
    
    useEffect(() => {
        getExpenseLabels()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <ExpenseLabel 
                list={dataExpenseLabels} 
                loading={loading} 
                onloadData={getExpenseLabels}
                />
            </div>
        </Fragment>
    )
}

export default ExpenseLabels