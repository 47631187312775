import React, { FC } from 'react'
import { Descriptions, Card, Divider } from 'antd'
interface ViewDataProps {
    data: any
    allows: any
}

const ViewData: FC<ViewDataProps> = ({ data, allows }) => {

    const role: any = [
        {
            "role": "DASHBOARD_PAGE",
            "menu": "ภาพรวม"
        },
        {
            "role": "TRANSFER_PAGE",
            "menu": "รายการโอนเงิน"
        },
        {
            "role": "EMPLOYEE_PAGE",
            "menu": "พนักงาน"
        },
        {
            "role": "ACCOUNT_PAGE",
            "menu": "บัญชีธนาคาร"
        },
        {
            "role": "USER_PAGE",
            "menu": "ผู้ใช้งาน"
        },
        {
            "role": "DEPARTMENT_PAGE",
            "menu": "ตั้งค่าแผนก"
        },
        {
            "role": "POSITION_PAGE",
            "menu": "ตั้งค่าตำแหน่ง"
        },
        {
            "role": "BANK_PAGE",
            "menu": "ตั้งค่าธนาคาร"
        },
        {
            "role": "EXPENSE_LABEL_PAGE",
            "menu": "ตั้งค่าค่าใช้จ่าย"
        },
        {
            "role": "SECURITY_PAGE",
            "menu": "ความปลอดภัย 2FA"
        },
        {
            "role": "COMPANY_PAGE",
            "menu": "ตั้งค่าบริษัท"
        },
    ]

    return (
        <>
            <Card>
                <Descriptions title="ข้อมูลผู้ใช้งาน" column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                    <Descriptions.Item label="ชื่อ - นามสกุล">{data.first_name}</Descriptions.Item>
                    <Descriptions.Item label="ชื่อผู้ใช้งาน">{data.username}</Descriptions.Item>
                    <Descriptions.Item label="สิทธิ์">{data.role.charAt(0).toUpperCase() + data.role.slice(1)}</Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title="สิทธิ์การเข้าถึง" column={2} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }} colon={false}>
                    {allows.map((item: any, index: number) =>
                        <Descriptions.Item label="-" key={index}>{role.find((obj: any) => obj.role === item) ? role.find((obj: any) => obj.role === item).menu : item}</Descriptions.Item>
                    )}
                </Descriptions>
            </Card>
        </>
    )
}
export default ViewData
