import React, { FC } from 'react'
import styled from 'styled-components'
import { Skeleton, Empty } from 'antd';

interface TableProps {
    list: any
    expenseLabels: any
    loading: boolean
    textHeader: string
    dateHeader: string
    dateExport: string
    companyShow: string
    departmentShow: string
    positionShow: string
}

const Table: FC<TableProps> = ({ list, expenseLabels, loading, textHeader, dateHeader, dateExport, companyShow, departmentShow, positionShow }) => {
    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    return (
        <>
            <TableWrapper className="table-responsive">
                {loading ?
                    <Skeleton active />
                    :
                    <>
                        {Object.values(list).length > 0 ?
                            <table className="table table-striped mt-4" id="table-summary">
                                <thead>
                                    <tr key={'main-1'} >
                                        <th key={'th-no1'} align="center" style={{ textAlign: 'center' }} colSpan={Object.values(expenseLabels).length + 3}>{textHeader}</th>
                                    </tr>
                                    <tr key={'main-2'} >
                                        <th key={'th-no2'} align="center" style={{ textAlign: 'center' }} colSpan={Object.values(expenseLabels).length + 3}>{dateHeader}</th>
                                    </tr>
                                    <tr key={'main-3'} >
                                        <th key={'th-no3-1'} align="left" style={{ textAlign: 'left' }} colSpan={(Object.values(expenseLabels).length + 3) / 3}>
                                            {companyShow && <>{companyShow} </>}
                                            {departmentShow &&
                                                <>
                                                    {departmentShow}
                                                    {positionShow && ` / ${positionShow}`}
                                                </>
                                            }
                                        </th>
                                        <th key={'th-no3-2'} align="right" style={{ textAlign: 'right' }} colSpan={(Object.values(expenseLabels).length + 3) / 3}>{dateExport}</th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr key={'tr-1'}>
                                        <th key={'th-no'}>#</th>
                                        <th key={'th-name'}>ชื่อ</th>
                                        <th key={'th-name'}>บริษัท</th>
                                        {Object.values(expenseLabels).map((data: any, index: number) => (
                                            <th key={'th-' + data.sequence}>{data.expense_label_name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/**
                            // * Data : loop data
                        **/}
                                    {
                                        Object.values(list).map((data: any, index: number) => (
                                            <tr key={'tr-2-' + index}>
                                                <td key={'id-' + data.employee_id}>{index + 1}</td>
                                                <td key={'nickname-' + data.employee_id}>{data.name} ({data.nickname})</td>
                                                <td key={'company-' + data.employee_id}>{data.company_name}</td>
                                                {Object.values(data.expense_labels).map((el: any, index_el: number) => (
                                                    <td key={'el-' + el.sequence}>{numberWithCommas(el.amount)}</td>
                                                ))}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                {/**
                        // * Footer : Summary amount
                        // tag "TableSummary" is styled lib.
                    **/}
                                <tbody>
                                    <TableSummary key={'tr-3'}>
                                        <td key={'sum-row'} colSpan={2}></td>
                                        <td key={'sum'}>รวม</td>
                                        {Object.values(expenseLabels).map((data: any, index: number) => (
                                            <th key={'sum-' + data.sequence}>{numberWithCommas(data.total_amount)}</th>
                                        ))}
                                    </TableSummary>
                                </tbody>
                            </table>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                }
            </TableWrapper>
        </>
    );
}

export default Table

const TableWrapper = styled.div`
    table > :not(:first-child) {
        border-top: 0;
    }

    thead {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        white-space: nowrap;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: #ccc;
        }
    }
`

const TableSummary = styled.tr`
    background: #eee;
    font-weight: bold;
`