import React, { FC } from 'react'
import styled from 'styled-components'
import MultipleData from '../../components/Dashboard/MultipleData'
import ListStatusBadge from '../../components/Elements/ListStatusBadge'
import { Table } from 'antd';
import { Link } from 'react-router-dom'

interface ListTableProps {
   list: object
   loading: boolean
}

const ListTable: FC<ListTableProps> = ({ list, loading }) => {
    const [filterText, setFilterText] = React.useState(''); // eslint-disable-line

    const statusColor : any = {
        'error' : '255, 0, 0',
        'pending' : '160, 160, 160',
        'processing' : '24, 144, 255',
        'success' : '19, 155, 130',
        'verified' : '24, 144, 255',
    }

	const filteredItems = (list as any).filter(
        (item: any) => (
            (item.employee_name && item.employee_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.employee_nickname && item.employee_nickname.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.employee_bank_name && item.employee_bank_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.employee_bank_account && item.employee_bank_account.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.account_bank_name && item.account_bank_name.toLowerCase().includes(filterText.toLowerCase()))
        ),
    );

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    const columns = [
        {
            title: 'พนักงาน',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.employee_nickname}</div>
                        <small className="text-muted">{row.employee_name}</small>
                    </TextNoWrap>
                )
            },
            width: '180px',
        },
        {
            title: 'โอนจาก',
            render: (row: any) => (
                <TextNoWrap>
                    <MultipleData name={row.account_bank_name} dateTime={row.account_bank_account}/>
                </TextNoWrap>
            ),
        },
        {
            title: 'จำนวนเงิน',
            render: (row: any) => <TextNoWrap><MultipleData name={`฿ ${numberWithCommas(row.amount)}`} dateTime={row.remark && `* ${row.remark}`} wrap={true}/></TextNoWrap>,
        },
        {
            title: 'ไปยัง',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.employee_bank_account_name ? row.employee_bank_account_name : '-'}</div>
                        <div><small className="text-muted">{row.employee_bank_name}</small></div>
                        <div><small className="text-muted">{row.employee_bank_account}</small></div>
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'สถานะ',
            render: (row: any) => (
                <div>
                    <ListStatusBadge status={row.status}  />
                </div>
            ),
            width: '200px',
        },
        {
            title: 'เวลาทำรายการ',
            render: (row: any) => {
                const colorStatus = row.worker_status && Object.values(statusColor)[Object.keys(statusColor).indexOf(row.worker_status)]
                return   (
                    <div>
                        <div>{row.createdAt} </div>
                        <Badge className="d-block badge" bgColor={colorStatus}>{`worker: ${row.worker_status}`}</Badge>
                    </div>
                )
            },
            width: '200px',
        }
    ]

    return (
        <ListTableCard className="p-3 p-md-4 bg-white mb-4">
            <div className="card-head">
                <div className="row mb-md-3 align-items-md-center">
                    <div className="col-6 col-md-auto d-flex align-items-center">
                        <h2>รายการโอนเงิน</h2>
                    </div>
                    <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                        <Link
                            to={'/transfer'}
                            className="btn btn-primary"
                        >
                            <span>ดูรายการทั้งหมด</span>
                        </Link>
                    </div>
                </div>
            </div>
            <Table
                columns={columns as any}
                rowKey={record => record.id}
                dataSource={filteredItems}
                loading={loading}
                scroll={{ x: true }}
                pagination={false}
                sticky={{ offsetHeader: 57 }}
            />
        </ListTableCard>
	);
}

export default ListTable

const ListTableCard = styled.div`
    background: #fff;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`

const Badge = styled.span<{bgColor: string}>`
	font-weight: normal;
	color: rgb(${props => props.bgColor});
    background: rgba(${props => props.bgColor}, 0.1);
`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`