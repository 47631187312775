import React, { Fragment, useEffect, useState } from 'react'
import EmployeeTable from '../../components/Employees/EmployeeTable'
// import EmployeesMockup from '../../mockup-json/EmployeesMockup.json'
// import BanksMockup from '../../mockup-json/BanksMockup.json'
import SendAPI from '../../utils/SendAPI'

const Employees = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataEmployees, setDataEmployees] = useState<any>([])
    const [dataBanks, setDataBanks] = useState<any>([])
    const [dataDepartments, setDataDepartments] = useState<any>([])
    const [dataAccounts, setDataAccounts] = useState<any>([])
    const [dataCompanys, setDataCompanys] = useState<any>([])
    const getEmployees = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/employees",
            method: "get",
        });

        if (dataResponse.status === 200) {
            setDataEmployees(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const getBanks = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/banks",
            method: "get",
        });

        if (dataResponse.status === 200) {
            setDataBanks(dataResponse.data?.data)
        }
        return dataResponse
    }

    const getDepartments = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/departments",
            method: "get",
        });

        if (dataResponse.status === 200) {
            setDataDepartments(dataResponse.data?.data)
        }
        return dataResponse
    }

    const getAccounts = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/accounts?status=enable",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataAccounts(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }

    const getCompanys = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/companies",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataCompanys(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }

    useEffect(() => {
        getEmployees()
        getBanks()
        getDepartments()
        getAccounts()
        getCompanys()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <EmployeeTable
                    list={dataEmployees}
                    banks={dataBanks}
                    departments={dataDepartments}
                    accounts={dataAccounts}
                    companys={dataCompanys}
                    loading={loading}
                    onloadData={getEmployees}
                />
            </div>
        </Fragment>
    )
}

export default Employees