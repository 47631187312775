import React, { FC, useEffect, useState } from 'react'
import { 
    Drawer, 
    Button, 
    Space,
    // notification,
    DatePicker,
    Table} from 'antd'
import SendAPI from '../../utils/SendAPI'
import styled from 'styled-components'
import moment from 'moment'
import Swal from 'sweetalert2'
// import { LoadingOutlined, PlusOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import CreateTransaction from '../Transfer/CreateTransaction'
import CreateExpenses from '../EmployeeSummary/CreateExpenses'
import ViewExpenses from '../EmployeeSummary/ViewExpenses'

interface PayrollAdvanceEmployeeProps {
    employeeList: any
    employee: any
	closeDrawer: () => void
	isOpenDrawer: boolean
	onloadData: () => void
}

// interface PayrollAdvanceEmployeeUpdateProps {
//     id: number
// 	nickname: string
// 	tel?: string
// 	name: string
// 	bank_id?: number
// 	bank_account_name?: string
// 	bank_account?: string
// 	position_id: number
// 	salary?: number
// 	status: string
// 	work_start?: string
// 	remark?: string
// 	identification_card_image?: string
// }

const PayrollAdvanceEmployee: FC<PayrollAdvanceEmployeeProps> = ({ closeDrawer, isOpenDrawer, onloadData, employeeList, employee }) => {
    // const [startSummaryDrawer, setStartSummaryDrawer] = useState(false)
    // const toggleStartSummary = () => setStartSummaryDrawer(!startSummaryDrawer)

    const [monthSearch, setMonthSearch] = useState<any>()
    const [dateCreate, setDateCreate] = useState<any>()
    const monthFormat = 'MM/YYYY';

    const { sendAPIWithJWT } = SendAPI();
    const [summary, setSummary] = useState(0)
    // const [salary, setSalary] = useState(0)
    const [sumTotal, setSumTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    // let data: any = []

    const [createTransactionModal, setCreateTransactionModal] = useState(false)
    const [createExpensesModal, setCreateExpensesModal] = useState(false)
    const [viewExpensesDrawer, setViewExpensesDrawer] = useState(false)
	const toggleTransactionModal = () => setCreateTransactionModal(!createTransactionModal)
	const toggleExpensesModal = () => setCreateExpensesModal(!createExpensesModal)
    const toggleViewExpenses = () => setViewExpensesDrawer(!viewExpensesDrawer)
    const [finances, setFinances] = useState('')
    const handleViewData = (object: any) => setFinances(object)
    
    const [dataEmployee, setDataEmployee] = useState<any>([])
    const [dataOjects, setDataOjects] = useState<any>([])
    const [dataExpenseLabels, setDataExpenseLabels] = useState<any>([])
    const [dataAccounts, setDataAccounts] = useState<any>([])


    const handleDeleteData = (id: number) => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: "คุณต้องการลบรายการนี้ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dataResponse: any = await sendAPIWithJWT({
                    path : `/finances/${id}`,
                    method : "delete",
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true})
                        onSearch()
                        
                }else{
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
            }
        })
    };

    const getAccounts = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/accounts?status=enable",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataAccounts(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const getExpenseLabels = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/expense-labels?status=enable",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataExpenseLabels(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }

    const getTransections = async (id:number, mount: any) => {
        const startOfMonth = moment(mount).startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment(mount).endOf('month').format('YYYY-MM-DD');
        const dataResponse: any = await sendAPIWithJWT({
            path : `/finances?employee_id=${id}&sdate=${startOfMonth}&edate=${endOfMonth}&expense_label_id=3`,
            method : "get",
        });
        // expense_label_id=1
        if (dataResponse.status === 200) {
            setDataEmployee(dataResponse.data)
            if (dataResponse.data.employee.salary !== 0) {
                setDataOjects(dataResponse.data.data)
            }
            setDataEmployee(dataResponse.data.employee)
            // setSalary(dataResponse.data.employee.salary)
            // คำนวณยอดเงิน
            setSummary(dataResponse.data.data.reduce((curr: number, item: any) => item.amount + curr, 0));

            // setDataEmployees(dataResponse.data?.employee)
        }
        setLoading(false)
        return dataResponse
    }

    const onSearch = async () => {
        await getTransections(employee.id, monthSearch)
    }

    const changeMonthSearch = async (date: any, dateString: string) => {
        await setMonthSearch(date)
        await setDateCreate(date)
    }

    useEffect(() => {
        if (employee || isOpenDrawer) {
            setMonthSearch(moment())
            setDateCreate(moment())    
            // onSearch()
            getAccounts()
            getExpenseLabels()
        }
    }, [employee, isOpenDrawer]) // eslint-disable-line

    useEffect(() => {
        setSumTotal(summary)
    }, [summary])

    useEffect(() => {
        if (employee) {
            onSearch()
        }
    }, [employee, monthSearch, dateCreate]) // eslint-disable-line

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    const amountFormat = (amount: number) => {
        if (amount >= 0) {
            return (<span style={{whiteSpace: 'nowrap', color: '#139B82'}}>+ ฿ {numberWithCommas(amount)}</span>)
        } else if(amount < 0) {
            return (<span style={{whiteSpace: 'nowrap', color: '#DF4B4A'}}>- ฿ {numberWithCommas(Math.abs(amount))}</span>)
        } else {
            return (<span style={{whiteSpace: 'nowrap'}}>฿ {numberWithCommas(amount)}</span>)
        }
    }

    const columns = [
        {
            title: 'ประเภทรายการ',
            render: (row: any) => {
                return (
                    <div style={{whiteSpace: 'nowrap'}}>
                    {row.id !== 0
                        ? <div
                            className="btn-link-view"
                            onClick={() => {
                                handleViewData(row)
                                toggleViewExpenses()
                            }}>
                                {row.expense_label_name ? row.expense_label_name : '-'}
                            </div>
                        : <div>{row.expense_label_name ? row.expense_label_name : '-'}</div>
                    }
                    <small className="text-muted">{row.remark}</small>
                    </div>
                )
            },
        },
        {
            title: 'จำนวนเงิน',
            render: (row: any) => amountFormat(row.amount)
        },
        {
            title: 'วันที่',
            render: (row: any) => row.date
        },
        {
            title: 'วันที่ทำรายการ',
            render: (row: any) => row.createdAt
        },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <>{row.id !== 0 && <Space size={0}><button className="btn btn-danger btn-sm" onClick={() => {handleDeleteData(row.id)}}>ลบ</button></Space>}</>
                )
            },
        }
    ]
    
	return (
        <>
        <Drawer
            title={`เบิกเงินล่วงหน้า`}
            onClose={closeDrawer}
            visible={isOpenDrawer}
            bodyStyle={{ paddingBottom: 80 }}
            width={ global.window.innerWidth <= 768 ? global.window.innerWidth : global.window.innerWidth  * .8}
            className="drawerSalary"
            extra={
            <Space>
                <Button onClick={closeDrawer} size="large">ปิด</Button>
            </Space>
            }
        >
            <ListTableCard className="bg-white mb-4">
            <div className="card-head">
                <div className="row mb-md-3 align-items-md-center">
                    <div className="col-12 col-md-auto mb-3 mb-md-0 mb-md-0">
                        <h2 className="my-auto me-3 mb-2">{(Object.keys(employee).length > 0) ? `${employee.name} (${employee.nickname})` : ''} </h2>
                        <p className="my-auto me-3">{(Object.keys(employee).length > 0) ? `แผนก : ${employee.department_name}` : ''} </p>
                        <p className="my-auto me-3">{(Object.keys(employee).length > 0) ? `ตำแหน่ง : ${employee.position_name}` : ''} </p>
                    </div>
                    <div className="col-6 col-md-auto ms-md-auto ps-md-0">
                        <button
                            className="btn btn-primary ms-auto ms-md-0 me-2"
                            onClick={() => toggleTransactionModal()}
                            disabled={dataEmployee.bank_account_name && dataEmployee.bank_account && dataEmployee.bank_name ? false : true}
                        >
                            <i className="far fa-plus me-3"></i>
                            <span>โอนเงิน</span>
                        </button>
                    </div>
                    <div className="col-6 col-md-auto ps-md-0">
                        <button
                            className="btn btn-info ms-auto ms-md-0"
                            onClick={() => toggleExpensesModal()}
                        >
                            <i className="far fa-plus me-3"></i>
                            <span>เบิกเงินล่วงหน้า</span>
                        </button>
                    </div>
                </div>
                <div className="ms-md-auto filter-wrapper mt-3 mt-md-0">
                    <div className="filter-group">
                        <Space
                            direction="vertical"
                            size={12}
                        >
                            <DatePicker
                                value={moment(monthSearch)}
                                format={monthFormat} picker="month"
                                onChange={(e, i) => {
                                    changeMonthSearch(e, i)
                                    // onSearch()
                                }}
                            />
                        </Space>
                        <button type="button" className="btn btn-outline-primary ms-3" onClick={()=>onSearch()}>ค้นหา</button>
                    </div>
                </div>
            </div>
            <Table
                columns={columns as any}
                dataSource={dataOjects}
                rowKey={record => record.id}
                loading={loading}
                pagination={false}
                summary={() => (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}><b>รวม</b></Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={3}><b>฿ {numberWithCommas(sumTotal)}</b></Table.Summary.Cell>
                    </Table.Summary.Row>
                )}
                scroll={{ x: true }}
            />
            </ListTableCard>
            <CreateTransaction
                employeeList={[employee]}
                accountList={dataAccounts}
                employeeId={employee.id}
                amount={Math.abs(sumTotal)}
	            isOpenCreateData={createTransactionModal}
                closeCreateData={() => toggleTransactionModal()}
                onloadData={() => onSearch()}
            />
             <CreateExpenses
                date={monthSearch}
                expenseLabelList={dataExpenseLabels}
                expenseLabelID={3}
                employeeID={employee.id}
	            isOpenModal={createExpensesModal}
                closeCreateModal={() => toggleExpensesModal()}
                onloadData={() => onSearch()}
            />
            <ViewExpenses
                listData={finances as any}
	            isOpenViewData={viewExpensesDrawer}
                closeViewData={() => toggleViewExpenses()}
            />
        

        {/* <Drawer
            title="Two-level Drawer"
            width={320}
            closable={false}
            onClose={() => toggleStartSummary()}
            visible={startSummaryDrawer}
          >
          This is two-level drawer
        </Drawer> */}
            
        </Drawer>
        </>
	)
}
export default PayrollAdvanceEmployee




const ListTableCard = styled.div`
    background: #fff;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`