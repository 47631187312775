import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
// import DataTable from 'react-data-table-component';
import CreateTransaction from '../../components/Transfer/CreateTransaction'
import ViewTransfer from './ViewTransfer'
import SlipModal from './SlipModal'
import ListStatusBadge from '../../components/Elements/ListStatusBadge'
import { Input, Table, Space, Select, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2'
import SendAPI from '../../utils/SendAPI'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'
import moment from 'moment'
interface TransferTableProps {
    list: any
    employees: any
    accounts: any
    departments: any
    companys: any
    loading: boolean
    onloadData: () => void
    onDateSearch: (e: any) => void
}

const TransferTable: FC<TransferTableProps> = ({ list, employees, accounts, departments, companys, loading, onloadData, onDateSearch }) => {
    const { RangePicker } = DatePicker
    const dateFormat = 'DD/MM/YYYY'

    const { sendAPIWithJWT } = SendAPI();
    const [filterText, setFilterText] = useState('')
    const [filterStatus, setFilterStatus] = useState('')
    const [filterStatusWorker, setFilterStatusWorker] = useState('')
    const [filterCompany, setFilterCompany] = useState('')
    const [filterDepartment, setFilterDepartment] = useState('')
    const [filterPosition, setFilterPosition] = useState('')

    const [dataOjects, setDataOjects] = useState<any>([])
    const [positionList, setPositionList] = useState<any>([])
    const [btnLoading, setBtnLoading] = useState(false)


    const [duplicateTransaction, setDuplicateTransaction] = useState<Object>({})
    const handleData = (object: any) => setDuplicateTransaction(object)


    const statusColor: any = {
        'error': '255, 0, 0',
        'pending': '160, 160, 160',
        'processing': '24, 144, 255',
        'success': '19, 155, 130',
        'verified': '24, 144, 255',
    }

    const workerStatusTH: any = {
        'error': 'ไม่สำเร็จ',
        'pending': 'รอดำเนินการ',
        'processing': 'กำลังดำเนินการ',
        'verified': 'ตรวจสอบบัญชีแล้ว',
        'success': 'สำเร็จ',
    }

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    const [createTransactionModal, setCreateTransactionModal] = useState(false)
    const [viewDataDrawer, setViewDataDrawer] = useState(false)
    const [viewSlipModal, setViewSlipModal] = useState(false)
    const [transaction, setTransaction] = useState('')
    const toggle = () => setCreateTransactionModal(!createTransactionModal)
    const toggleViewData = () => setViewDataDrawer(!viewDataDrawer)
    const toggleViewSlip = () => setViewSlipModal(!viewSlipModal)
    const handleViewData = (object: any) => setTransaction(object)
    const { Option } = Select;

    const handleStatusData = async (status: string, id: number, option?: string) => {
        let statusTitle = (status === 'approved') ? "อนุมัติ" : "ยกเลิก"
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: `คุณต้องการ${statusTitle}รายการนี้ใช่หรือไม่`,
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed && status && id) {
                setBtnLoading(true)
                const dataResponse: any = await sendAPIWithJWT({
                    path: `/transfers/${id}`,
                    method: "patch",
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        "status": status,
                        "option": option
                    }
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    list[list.findIndex((el: any) => el.id === id)].status = dataResponse.data?.data.status
                    list[list.findIndex((el: any) => el.id === id)].worker_status = dataResponse.data?.data.worker_status
                } else {
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
                setBtnLoading(false)
            }
        })
    };

    const columns = [
        {
            title: '#',
            render: (row: any) => {
                return (
                    <>
                        <div>{row.serial}</div>
                        <small className="text-muted">{`#${row.id}`}</small>
                    </>
                )
            },
            width: '72px',
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => a.serial - b.serial,
        },
        {
            title: 'พนักงาน',
            render: (row: any) => {
                return (
                    <>
                        <TextNoWrap>
                            <div
                                className="btn-link-view"
                                onClick={() => {
                                    handleViewData(row)
                                    toggleViewData()
                                }}
                            >
                                {row.employee_nickname}
                            </div>
                            <small className="text-muted">{row.employee_name}</small>
                        </TextNoWrap>
                        <small className="text-muted">({findCompanyName(row.company_id)})</small>
                    </>
                )
            },
            width: '180px',
        },
        {
            title: 'จำนวนเงิน',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>฿ <strong>{numberWithCommas(row.amount)}</strong></div>
                        {row.remark &&
                            <small className="text-muted wrap">{`* ${row.remark}`}</small>
                        }
                    </TextNoWrap>
                )
            },
            width: '120px',
        },
        {
            title: 'โอนจาก',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.account_bank_name}</div>
                        <small className="text-muted">{row.account_bank_account}</small>
                    </TextNoWrap>
                )
            },
            width: '160px',
        },
        {
            title: 'ไปยัง',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.employee_bank_account_name}</div>
                        <div><small className="text-muted">{row.employee_bank_name}</small></div>
                        <div><small className="text-muted">{row.employee_bank_account}</small></div>
                    </TextNoWrap>
                )
            },
            width: '180px',
        },
        {
            title: 'สถานะ',
            render: (row: any) => {
                return (
                    <ListStatusBadge status={row.status} />
                )
            },
            width: '120px',
        },
        {
            title: 'เวลาทำรายการ',
            render: (row: any) => {
                const colorStatus = row.worker_status && Object.values(statusColor)[Object.keys(statusColor).indexOf(row.worker_status)]
                return (
                    <>
                        <div>{row.createdAt}</div>
                        <Badge className="d-block badge" bgColor={colorStatus}>{`worker: ${row.worker_status && Object.values(workerStatusTH)[Object.keys(workerStatusTH).indexOf(row.worker_status)]}`}</Badge>
                    </>
                )
            },
            width: '180px',
        },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <div>
                        {row.status === 'pending'
                            && (
                                <Space size={0}>
                                    <button className="btn btn-success btn-sm text-white me-2" disabled={btnLoading} onClick={() => { handleStatusData('approved', row.id, 'auto') }}>อนุมัติ</button>
                                    <button className="btn btn-warning btn-sm text-white me-2" disabled={btnLoading} onClick={() => { handleStatusData('approved', row.id, 'manual') }}>Manual</button>
                                    <button className="btn btn-danger btn-sm" disabled={btnLoading} onClick={() => { handleStatusData('reject', row.id) }}>ยกเลิก</button>
                                </Space>
                            )
                        }
                        {row.status === 'approved' && row.worker_status === 'error' &&
                            <div>
                                <Space size={0}>
                                    <button className="btn btn-warning btn-sm text-white me-2" onClick={() => { handleData(row); toggle(); }}>ทำซ้ำ</button>
                                </Space>
                            </div>
                        }
                        {row.worker_status === 'success' && row.transaction_log.transaction_id !== null
                            && (
                                <Space size={0}>
                                    <button className="btn btn-primary btn-sm text-white me-2" disabled={btnLoading} onClick={() => {
                                        handleViewData(row)
                                        toggleViewSlip()
                                    }}>สลิปโอนเงิน</button>
                                </Space>
                            )
                        }
                    </div>
                )
            },
            width: '220px',
        }
    ]

    useEffect(() => {
        if (Object.keys(list).length > 0) {
            let filteredItems = (list as any).filter(
                (item: any) => (
                    (item.employee_name && item.employee_name.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.employee_nickname && item.employee_nickname.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.employee_bank_name && item.employee_bank_name.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.employee_bank_account && item.employee_bank_account.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.account_bank_name && item.account_bank_name.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.created_user_name && item.created_user_name.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.id && item.id.toString().includes(filterText.toLowerCase()))
                ),
            );

            if (filterStatus) {
                filteredItems = (filteredItems as any).filter(
                    (item: any) => (
                        (item.status && item.status.toString().includes(filterStatus.toLowerCase()))
                    ),
                );
            }
            if (filterStatusWorker) {
                filteredItems = (filteredItems as any).filter(
                    (item: any) => (
                        (item.worker_status && item.worker_status.toString().includes(filterStatusWorker.toLowerCase()))
                    ),
                );
            }
            if (filterCompany) {
                filteredItems = (filteredItems as any).filter(
                    (item: any) => item.company_id === filterCompany,
                );
            }
            if (filterDepartment) {
                setPositionList([])
                filteredItems = (filteredItems as any).filter(
                    (item: any) => item.department_id === filterDepartment,
                );
                const positionList = (departments as any).find(
                    (item: any) => item.id === filterDepartment,
                );
                setPositionList(positionList?.positions)
            }
            if (filterPosition) {
                filteredItems = (filteredItems as any).filter(
                    (item: any) => item.position_id === filterPosition,
                );
            }

            filteredItems.forEach((item: any, index: number) => { item.serial = index + 1; });
            setDataOjects(filteredItems)
        } else {
            setDataOjects(list)
        }
    }, [list, filterText, filterCompany, filterDepartment, filterPosition, setDataOjects, filterStatus, filterStatusWorker, departments])

    const findCompanyName = (company_id: number) => {
        const company: any = Object.values(companys).find((company: any) => (company.id === company_id))
        return company ? company.name : ""
    }

    const getCompanyOption = Object.values(companys).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    const getDepartmentOption = Object.values(departments).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    const getPositionsOption = Object.values(positionList).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    return (
        <>
            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <div className="card-head">
                    <div className="row mb-md-3 align-items-md-center">
                        <div className="col-6 col-md-auto d-flex align-items-center">
                            <h2>รายการโอนเงิน</h2>
                        </div>
                        <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                            <button
                                className="btn btn-primary ms-auto ms-md-0"
                                onClick={() => { handleData({}); toggle(); }}
                            >
                                <i className="far fa-plus me-3"></i>
                                <span>โอนเงิน</span>
                            </button>
                        </div>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <Space
                                direction="vertical"
                                size={12}
                            >
                                <RangePicker
                                    // defaultValue={[moment().startOf('isoWeek'), moment().endOf('isoWeek')]}
                                    placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                                    ranges={{
                                        'วันนี้': [moment(), moment()],
                                        'เมื่อวาน': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'อาทิตย์นี้': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                        'อาทิตย์ที่แล้ว': [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')],
                                        'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                        'เดือนที่แล้ว': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    format={dateFormat}
                                    onChange={onDateSearch}
                                />
                            </Space>
                            <button type="button" className="btn btn-outline-primary" onClick={onloadData}>ค้นหา</button>
                        </div>
                        <div className="filter-group">
                            <Input
                                size="large"
                                addonBefore={<SearchOutlined style={{ marginBottom: '6px' }} />}
                                allowClear
                                placeholder="ค้นหา"
                                value={filterText ?? ''}
                                onChange={(e: any) => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <label>บริษัท : </label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterCompany}
                                onSelect={(value: any) => {
                                    setFilterCompany(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">ทั้งหมด</Option>
                                {getCompanyOption}
                            </Select>
                        </div>
                        <div className="filter-group">
                            <label>แผนก : </label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterDepartment}
                                onSelect={(value: any) => {
                                    setFilterPosition("")
                                    setPositionList([])
                                    setFilterDepartment(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">ทั้งหมด</Option>
                                {getDepartmentOption}
                            </Select>
                        </div>
                        <div className="filter-group">
                            <label>ตำแหน่ง : </label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterPosition}
                                onSelect={(value: any) => {
                                    setFilterPosition(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">ทั้งหมด</Option>
                                {getPositionsOption}
                            </Select>
                        </div>
                        <div className="filter-group">
                            <label>สถานะ :</label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterStatus}
                                onSelect={(value: any) => {
                                    setFilterStatus(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">ทั้งหมด</Option>
                                <Option value="pending">รออนุมัติ</Option>
                                <Option value="approved">อนุมัติ</Option>
                                <Option value="reject">ไม่อนุมัติ</Option>
                            </Select>
                        </div>
                        <div className="filter-group">
                            <label>Worker :</label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterStatusWorker}
                                onSelect={(value: any) => {
                                    setFilterStatusWorker(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">ทั้งหมด</Option>
                                <Option value="pending">รอดำเนินการ</Option>
                                <Option value="processing">กำลังดำเนินการ</Option>
                                <Option value="verified">ตรวจสอบบัญชีแล้ว</Option>
                                <Option value="success">สำเร็จ</Option>
                                <Option value="error">ไม่สำเร็จ</Option>
                            </Select>
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns as any}
                    dataSource={dataOjects}
                    rowKey={record => record.id}
                    loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                    onChange={ScrollToTopPagination}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50', '80', '100'],
                        position: ['topRight', 'bottomRight'],
                        locale: { items_per_page: " รายการ" }
                    }}
                />
            </ListTableCard>
            <CreateTransaction
                employeeList={employees as any}
                accountList={accounts as any}
                isOpenCreateData={createTransactionModal}
                closeCreateData={() => toggle()}
                onloadData={() => onloadData()}
                transaction={duplicateTransaction as any}
            />
            <ViewTransfer
                listData={transaction as any}
                isOpenViewData={viewDataDrawer}
                closeViewData={() => toggleViewData()}
            />
            <SlipModal
                data={transaction as any}
                isOpen={viewSlipModal}
                close={() => toggleViewSlip()}
            />
        </>
    );
}

export default TransferTable

const ListTableCard = styled.div`
    background: #fff;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

    .ant-picker {
        min-width: 254px;
        width: 254px;
        margin-right: 8px;
    }
`

const Badge = styled.span<{ bgColor: string }>`
	font-weight: normal;
	color: rgb(${props => props.bgColor});
    background: rgba(${props => props.bgColor}, 0.1);
`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`
