import React, { FC, useEffect, useState } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Select,
    Upload,
    Space,
    notification,
    DatePicker,
    Modal
} from 'antd'
import SendAPI from '../../utils/SendAPI'
import moment from 'moment'
import { LoadingOutlined, PlusOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';

interface EditEmployeeProps {
    bankList: object
    companyList: object
    departmentList: object
    employee: any
    closeEditDrawer: () => void
    isOpenEditDrawer: boolean
    onloadData: () => void
}

interface EditEmployeeUpdateProps {
    id: number
    nickname: string
    tel?: string
    name: string
    bank_id?: number
    bank_account_name?: string
    bank_account?: string
    position_id: number
    salary?: number
    status: string
    work_start?: string
    remark?: string
    identification_card_image?: string
}

const EditEmployee: FC<EditEmployeeProps> = ({ closeEditDrawer, isOpenEditDrawer, onloadData, bankList, companyList, departmentList, employee }) => {
    const nameTitle = "แก้ไขพนักงาน"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option, OptGroup } = Select;
    const [btnLoading, setBtnLoading] = useState(false)
    const [previewVisible, setPreviewVisible] = useState(false)

    const dateFormat: string = 'DD/MM/YYYY';
    const patternNumberBank: any = new RegExp("^([0-9][0-9]*|0)$")
    const patternNumber: any = new RegExp("^([-]?([0-9]+([.][0-9]*)?|[.][0-9]+))$")
    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    const [imageUrl, setImageUrl] = useState('')
    const [filenameImage, setFilenameImage] = useState('')

    const UpdateAccount = async (values: EditEmployeeUpdateProps) => {
        const updateResponse: any = await sendAPIWithJWT({
            path: `/employees/${employee.id}`,
            method: "put",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (updateResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: updateResponse.data?.message
            });
            setBtnLoading(false)
            closeEditDrawer()
            form.resetFields()
            onloadData()
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: updateResponse.message ? updateResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
        }

    }


    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: EditEmployeeUpdateProps) => {
                setBtnLoading(true)
                values.salary = Number(values.salary)
                values.work_start = values.work_start ? moment(values.work_start, dateFormat).format('YYYY-MM-DD') : ''
                values.identification_card_image = filenameImage ? filenameImage : ''
                UpdateAccount(values)
            })
            .catch((errorInfo) => { });
    };

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const customRequestUploadPicture = async (info: any) => {
        await getBase64(info.file, (ImageBase64: any) =>
            sendImage(ImageBase64)
        );
    }

    const sendImage = async (ImageBase64: any) => {
        const uploadImage: any = await sendAPIWithJWT({
            path: "/employees/idcard/upload",
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: {
                "image_base64": ImageBase64
            }
        });
        if (uploadImage.status === 200) {
            notification.success({
                message: 'Success',
                description: uploadImage.data?.message
            });
            setImageUrl(ImageBase64)
            setFilenameImage(uploadImage.data.filename)
            setLoadingImage(false)
            await sendAPIWithJWT({
                path: `/employees/${employee.id}/idcard/update`,
                method: "patch",
                headers: { 'Content-Type': 'application/json' },
                data: {
                    identification_card_image: uploadImage.data.filename
                }
            });
            onloadData()
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: uploadImage.message ? uploadImage.message : "เกิดข้อผิดพลาด"
            });
            setImageUrl('')
            setFilenameImage('')
            setLoadingImage(false)
        }
    }

    const handleUploadPicture = async (info: any) => {
        if (info.file.status === 'uploading') {
            setImageUrl('')
            setFilenameImage('')
            setLoadingImage(true)
        }
        if (info.file.status === 'done') {
            notification.error({
                message: 'Success',
                description: `${info.file.name} file uploaded successfully`
            });
        } else if (info.file.status === 'error') {
            notification.error({
                message: 'Error',
                description: `${info.file.name} file upload failed.`
            });
        }
    }

    const handleRemovePicture = async () => {
        setLoadingImage(true)
        const deleteImg: any = await sendAPIWithJWT({
            path: `/employees/idcard/delete?filename=${filenameImage}`,
            method: "delete"
        });
        if (deleteImg.status === 200) {
            setFilenameImage('')
            setImageUrl('');
            await sendAPIWithJWT({
                path: `/employees/${employee.id}/idcard/update`,
                method: "patch",
                headers: { 'Content-Type': 'application/json' },
                data: {
                    identification_card_image: ""
                }
            });
            onloadData()
        }
        setLoadingImage(false)
    }

    const beforeUploadPicture = async (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            notification.error({
                message: 'Warning',
                description: `You can only upload JPG/PNG file!`
            });
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            notification.error({
                message: 'Warning',
                description: `Image must smaller than 2MB!`
            });
        }
        return isJpgOrPng && isLt2M;
    }

    const downloadImage = async (filename: string) => {
        setLoadingImage(true)
        const downloadImg: any = await sendAPIWithJWT({
            path: `/employees/idcard/download?filename=${filename}`,
            method: "get"
        });
        if (downloadImg.status === 200) {
            setImageUrl(downloadImg.data.url);
        } else {
            setImageUrl('');
        }
        setLoadingImage(false)
    }

    useEffect(() => {
        setLoadingImage(false)
        if (form && isOpenEditDrawer && employee) {
            setImageUrl('');
            setFilenameImage(employee.identification_card_image)
            employee.identification_card_image && downloadImage(employee.identification_card_image)
            form.resetFields();
            form.setFieldsValue({
                nickname: employee.nickname,
                tel: employee.tel,
                name: employee.name,
                bank_id: employee.bank_id,
                bank_account_name: employee.bank_account_name,
                bank_account: employee.bank_account,
                company_id: employee.company_id,
                position_id: employee.position_id,
                salary: employee.salary !== 0 ? Number(employee.salary) : '',
                status: employee.status,
                work_start: employee.work_start ? moment(employee.work_start, 'DD/MM/YYYY') : null,
                remark: employee.remark,
            })
        }
    }, [form, employee, isOpenEditDrawer])// eslint-disable-line


    const getBankOption = Object.values(bankList).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    const getCompanyOption = Object.values(companyList).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    const getDepartmentOption = Object.values(departmentList).map((item: any) => {
        return (
            <OptGroup label={item.name} key={item.id}>
                {item.positions.map((option: any) => (
                    <Option key={option.id} value={option.id}>{option.name}</Option>
                ))}
            </OptGroup>
        )
    })

    const uploadButton = (
        <div>
            {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeEditDrawer}
                visible={isOpenEditDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeEditDrawer} size="large">ยกเลิก</Button>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large"
                            danger
                        >
                            <i className="far fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="EditEmployee"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="ชื่อ - นามสกุล"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ - นามสกุล' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อ - นามสกุล" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="nickname"
                                label="ชื่อเล่น"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อเล่น' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อเล่น" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="tel"
                                label="เบอร์โทรศัพท์"
                                rules={[{ required: false, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
                                hasFeedback
                            >
                                <Input placeholder="เบอร์โทรศัพท์" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_account_name"
                                label="ชื่อบัญชี"
                                rules={[{ required: false, message: 'กรุณากรอกชื่อบัญชี' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อบัญชี" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_account"
                                label="เลขที่บัญชี"
                                rules={[
                                    { required: false, message: 'กรุณากรอกเลขที่บัญชี' },
                                    { pattern: patternNumberBank, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback
                            >
                                <Input placeholder="เลขที่บัญชี" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_id"
                                label="ธนาคาร"
                                rules={[{ required: false, message: 'กรุณาเลือกธนาคาร' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือกธนาคาร"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getBankOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="company_id"
                                label="บริษัท"
                                rules={[{ required: false, message: 'กรุณาเลือกบริษัท' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือกบริษัท"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getCompanyOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="position_id"
                                label="แผนก/ตำแหน่ง"
                                rules={[{ required: true, message: 'กรุณาเลือกตำแหน่ง' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือกตำแหน่ง"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        if (option.children) {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                        } else if (option.label) {
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                        }
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }}
                                >
                                    {getDepartmentOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="salary"
                                label="เงินเดือน"
                                rules={[
                                    { required: false, message: 'กรุณากรอกเงินเดือน' },
                                    { pattern: patternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback
                            >
                                <Input placeholder="เงินเดือน" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="สถานะ"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                                hasFeedback
                            >
                                <Select placeholder="เลือกสถานะ">
                                    <Option value="enable">เปิด</Option>
                                    <Option value="disable">ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="work_start"
                                label="วันเริ่มงาน"
                                rules={[{ required: false, message: 'กรุณาเลือกวันเริ่มงาน' }]}
                            >
                                <DatePicker format={dateFormat} placeholder="วันเริ่มงาน" className="date-picker-employee" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <span>รูปบัตรประชาชน</span><br />
                            <Space>
                                <Upload
                                    name="identification_card_image"
                                    listType="picture-card"
                                    className="idcard-uploader"
                                    showUploadList={false}
                                    customRequest={customRequestUploadPicture}
                                    beforeUpload={beforeUploadPicture}
                                    onChange={handleUploadPicture}
                                    progress={{
                                        strokeColor: {
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        },
                                        strokeWidth: 3,
                                        format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
                                    }}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="IdCard" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                                {imageUrl &&
                                    <>
                                        <Button type="primary" onClick={() => setPreviewVisible(true)} icon={<EyeOutlined />} shape="circle" ></Button>
                                        <Button type="primary" onClick={() => handleRemovePicture()} icon={<DeleteOutlined />} shape="circle" danger></Button>
                                    </>
                                }
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="remark"
                                label="หมายเหตุ"
                                rules={[{ required: false, message: 'กรุณากรอกหมายเหตุ' }]}
                            >
                                <Input.TextArea rows={3} placeholder="หมายเหตุ" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-save me-2"></i> บันทึก</button>
                        <button type="button" className="btn btn-light me-3" onClick={() => closeEditDrawer()}>ยกเลิก</button>
                    </div>
                </Form>
            </Drawer>

            <Modal
                visible={previewVisible}
                title={'รูปบัตรประชาชน'}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={imageUrl} />
            </Modal>
        </>
    )
}
export default EditEmployee

