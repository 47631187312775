import React, { Fragment, useEffect, useState } from 'react'
import PositionTable from '../../components/Positions/Table'
import SendAPI from '../../utils/SendAPI'
const Positions = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataPositions, setDataPositions] = useState<any>([])
    const [dataDepartments, setDataDepartments] = useState<any>([])
    const getPositions = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/positions",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataPositions(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }
    const getDepartments = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/departments",
            method : "get",
        });
        
        if (dataResponse.status === 200) {
            setDataDepartments(dataResponse.data?.data)
        }
        return dataResponse
    }
    useEffect(() => {
        getPositions()
        getDepartments()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <PositionTable 
                list={dataPositions} 
                loading={loading} 
                onloadData={getPositions}
                departments={dataDepartments} />
            </div>
        </Fragment>
    )
}

export default Positions